import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const UFDiagramStrainerDetails = ({ isInge, isCIP }) => {
  const { data } = useSelector(state => state.UFStore);

  const xyPosition = useMemo(() => ({
    x: 1650,
    y: (!isInge || isCIP) ? 4450 : 4050
  }), [isInge, isCIP]);

  return (
    <>
      <BoldTextElement>
        <tspan {...xyPosition}>
          Strainer
        </tspan>
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x={xyPosition.x} y={xyPosition.y + 140}>
          {`${data.strainerSize} µm`}
        </tspan>
        <tspan x={xyPosition.x} y={xyPosition.y + 280}>
          {`${data.strainerRecovery} % Recovery`}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramStrainerDetails;
