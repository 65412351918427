import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { DupontString, IngeString } from "@utils/StringConstants";

import CustomCard from "@components/CustomCard";

import CustomHeading from "@common/styles/components/headings/CustomHeading";
import DynamicLoadder from "@common/utils/DynamicLoadder";

import { INGE_MODULE_TR_RANGES } from "../constants/UFConstants";
import MultiRangeSlider from "../table/MultiRangeSlider";
import MultiRangeSliderStyled from "../table/MultiRangeSliderStyled";
import TableComponent from "../table/TableComponent";

import "rc-slider/assets/index.css";

const UFRecommendedConfiguration = () => {
  const { activeUFModule, ufCompany, data: UFData, isUFConfigLoading } = useSelector(state => state.UFStore);
  const labelStrings = ufCompany === "INGE" ? IngeString : DupontString;

  const [sliderRange, setSliderRange] = useState({});

  useEffect(() => {
    if (activeUFModule?.tRack) {
      const ufmoduleId = activeUFModule?.ufmoduleId;
      const ingeTROption = `TR${UFData.skidsPerTrain}`;
      const step = 2;
      const { min, max } = INGE_MODULE_TR_RANGES[ufmoduleId][ingeTROption];
      setSliderRange({ min, max, step });
    } else {
      setSliderRange({ min: 6, max: 22, step: 1 });
    }
  }, [activeUFModule, UFData.skidsPerTrain]);

  return (
    <div className='card-wrapper-four'>
      <CustomCard
        header='Recommended Configurations'
        className='recomm-config'
        tooltipLabel='List of potential configurations which will meet the desired target operating flux.'
      >
        <DynamicLoadder isLoading={isUFConfigLoading}>
          {(activeUFModule?.integraPacInd || activeUFModule?.tRack) && (
            <div className='tracks-wrapper'>
              <div className='limit'>
                <CustomHeading label={labelStrings.recommConfigSliderMinRange} />
              </div>
              {sliderRange?.max && sliderRange?.min && (
                <MultiRangeSliderStyled>
                  <MultiRangeSlider {...sliderRange} />
                </MultiRangeSliderStyled>
              )}
              <div className='limit'>
                <CustomHeading label={labelStrings.recommConfigSliderMaxRange} />
              </div>
            </div>
          )}
          <TableComponent />
        </DynamicLoadder>
      </CustomCard>
    </div>
  );
};

export default UFRecommendedConfiguration;
