// This file is intended to add validation/utilitly functions for the activity monitor page
import { COMPANY_CODE, ERR_MSGS } from "@features/feedwater/uf/constants/UFConstants";
import { isIngeSelected } from "@features/feedwater/uf/ufUtils";

import { isDoseErr } from "./appUtils";

export const isCIPDoseErr = ufData => {
  const { alkaliEnabled_Ind_CIP, oxidantEnabled_Ind_CIP, mineralEnabled_Ind_CIP, organicEnabled_Ind_CIP } = ufData;
  const { mineralValue_CIP, organicValue_CIP, alkaliValue_CIP, oxidantValue_CIP } = ufData;
  const indicators = [alkaliEnabled_Ind_CIP, oxidantEnabled_Ind_CIP, mineralEnabled_Ind_CIP, organicEnabled_Ind_CIP];
  const doseValues = [mineralValue_CIP, organicValue_CIP, alkaliValue_CIP, oxidantValue_CIP];
  return isDoseErr({ indicators, doseValues });
};

export const isAcidCEBDoseErr = ufData => {
  const indicators = [ufData.mineralEnabled_Ind_CEB, ufData.organicEnabled_Ind_CEB];
  const doseValues = [ufData.mineralValue, ufData.organicValue];
  return isDoseErr({ indicators, doseValues });
};

export const isBaseCEBDoseErr = ufData => {
  const indicators = [ufData.alkaliEnabled_Ind_CEB, ufData.oxidantEnabled_Ind_CEB];
  const doseValues = [ufData.alkaliValue, ufData.oxidantValue];
  return isDoseErr({ indicators, doseValues });
};

export const isMiniCIPDoseErr = ufData => {
  if (parseInt(ufData.ufSpecialFeatureID) === 1) {
    return false;
  }
  const indicators = [
    ufData.mineralEnabled_Ind_MiniCIP,
    ufData.organicEnabled_Ind_MiniCIP,
    ufData.alkaliEnabled_Ind_MiniCIP,
    ufData.oxidantEnabled_Ind_MiniCIP,
  ];
  const { mineralValue_MiniCIP, organicValue_MiniCIP, oxidantValue_MiniCIP, alkaliValue_MiniCIP } = ufData;
  const doseValues = [mineralValue_MiniCIP, organicValue_MiniCIP, oxidantValue_MiniCIP, alkaliValue_MiniCIP];
  return isDoseErr({ indicators, doseValues });
};

// only for inge
export const isDisOxidantCEBDoseErr = ufData => {
  const { disOxidantEnabled_Ind_CEB, disOxidantValue } = ufData;
  return isDoseErr({ indicators: [disOxidantEnabled_Ind_CEB], doseValues: [disOxidantValue] });
};

export const getChemicalErrorMsg = ({ ufData, ufCompany }) => {
  const { acidCEB, alkaliOxidantCEB, disinfectionCEB, pUFTechnologyID } = ufData;
  let error = null;
  if (acidCEB > 0 && isAcidCEBDoseErr(ufData)) {
    error = ERR_MSGS.ACID_CEB_DOSE_ERROR;
  } else if (acidCEB <= 0 && !isAcidCEBDoseErr(ufData)) {
    error = ERR_MSGS.ACID_CEB_DESIGN_VALUE_ERROR;
  } else if (alkaliOxidantCEB > 0 && isBaseCEBDoseErr(ufData)) {
    error = ERR_MSGS.ALKALI_CEB__DOSE_ERROR;
  } else if (alkaliOxidantCEB <= 0 && !isBaseCEBDoseErr(ufData)) {
    error = ERR_MSGS.ALKALI_CEB_DESIGN_VALUE_ERROR;
  } else if (isCIPDoseErr(ufData) && ufCompany === COMPANY_CODE[1]) {
    error = ERR_MSGS.CIP_DOSE_ERROR;
  } else if (isMiniCIPDoseErr(ufData)) {
    error = ERR_MSGS.MINI_CIP_DOSE_ERROR;
  } else if (isIngeSelected(pUFTechnologyID) && disinfectionCEB > 0 && isDisOxidantCEBDoseErr(ufData)) {
    error = ERR_MSGS.DIS_OXIDANT_CEB_DOSE_ERROR;
  } else if (isIngeSelected(pUFTechnologyID) && disinfectionCEB <= 0 && !isDisOxidantCEBDoseErr(ufData)) {
    error = ERR_MSGS.DIS_OXIDANT_DESIGN_VALUE_ERROR;
  }
  return error;
};
