export const SESSION_TIMEOUT_MS = 1800000;
export const PROJECT_NAME_MAX_LENGTH = 20; //Length of project name

export const TECHNOLOGIES = {
  UF: "UF",
  IX: "IX",
  RO: "RO",
  IXD: "IXD",
  IXN: "IXN",
  IXB: "IXB",
  IXSD: "IXSD",
  IXMB: "IXMB",
  IXOS: "IXOS",
  CCRO: "CCRO",
  ROSC: "ROSC",
  IXCP: "IXCP",
};

export const CHEMICALS_SYMBOL_MAPPING = {
  "₀": "0",
  "₁": "1",
  "₂": "2",
  "₃": "3",
  "₄": "4",
  "₅": "5",
  "₆": "6",
  "₇": "7",
  "₈": "8",
  "₉": "9",
};

export const TEMPERARTURE_RANGES = {
  "°C": {
    soft: {
      min: 1,
      max: {
        UF: 40.0,
        IXD: 60.0,
        IXMB: 60.0,
      },
    },
    hard: {
      min: 0,
      max: {
        UF: 100.0,
        IXD: 100.0,
        IXMB: 100.0,
      },
    },
  },
  "°F": {
    soft: {
      min: 33.8,
      max: {
        UF: 104,
        IXD: 140,
        IXMB: 140,
      },
    },
    hard: {
      min: 0,
      max: {
        UF: 213.8,
        IXD: 213.8,
        IXMB: 213.8,
      },
    },
  },
};

export const FLOW_METRIC_UNIT = "m³/h";

export const DEFAULT_RECOVERY_VALUES = {
  UF: 99.12,
  IXD: 97,
  IXMB: 97,
  DEFAULT: 100,
};

export const STATUS_CODE = {
  SUCCESS: 200,
  NO_CONTENT: 204,
};

export const PERCENTAGE_RANGE = {
  min: 0,
  max: 100,
};
