export const UF_PAYLOAD_CHEMICAL_IDS = {
  CIP_org_acid: "organicChemId_CIP",
  CEB1_org_acid: "organicChemId",
  BW_ox: "oxidantID",
  CEB1_acid: "mineralChemId",
  CEB2_base: "alkaliChemId",
  CEB2_ox: "oxidantChemId",
  CEB3_ox: "disOxidantChemId",
  CIP_acid: "mineralChemId_CIP",
  CIP_base: "alkaliChemId_CIP",
  CIP_ox: "oxidantChemId_CIP",
  CIP_SLS: "oxidant2ChemId_CIP",
  mCIP_acid: "mineralChemId_MiniCIP",
  mCIP_org_acid: "organicChemId_MiniCIP",
  mCIP_base: "alkaliChemId_MiniCIP",
  mCIP_ox: "oxidantChemId_MiniCIP",
  mCIP_SLS: "oxidant2ChemId_MiniCIP",
};

export const UF_PAYLOAD_CHEMICAL_NAMES = {
  Citric_Acid: "Citric Acid(100)",
  FeCl3: "FeCl₃(100)",
  H2SO4: "H₂SO₄(98)",
  HCl: "HCl (32)",
  NaOCl: "NaOCl(12)",
  NaOH: "NaOH (50)",
  Oxalic_Acid: "Oxalic Acid(100)",
  SLS: "CH3(CH2)11SO4Na (100)",
  PACl: "PACl(5)",
};

export const CHEMICAL_DISPLAY_NAMES = {
  SLS: "SLS(100%)",
};

export const UF_PUMP_LIST = {
  feed: 53,
  BW: 54,
  CIP: 55,
  mCIP: 55,
  compressor: 56,
  metering: 57,
};

export const MODULE_DETAILS_MAPPING = {
  N_Part_number: "moduleName",
  N_Part_number_long: "newModuleLongName",
  Area_Module: "moduleArea",
  Vol_module: "v",
  Length_module: "l",
  Length_fibers: "fiberLength",
  N_capillary: "bores",
  N_Capillary_Ends: "ends",
  D_ID: "dId",
  D_OD: "dOd",
  Av: "av",
  P0: "p0",
  S0: "s0",
  S10: "s10",
  S20: "s20",
  S30: "s30",
  S40: "s40",
};

export const MODULE_DETAILS_MAPPING_UPPER_CASE = {
  Drinking_water_part_names: "drinkingWaterInd",
  IntegraPac: "integraPacInd",
  T_Rack: "tRack",
  Mem_Rack: "memRack",
  IntegraFlo: "integraFlowInd",
};

export const DURATION_FIELDS_MAPPING = {
  t_LF: "lF",
  t_FTL: "t_FTL",
  t_Drain_CEB: "drain",
  t_FF_CEB: "forwardFlush",
  t_AS_CEB: "ceb_AirScour",
  t_AS: "backwash_AirScour",
  t_Drain: "drain_backWash",
  t_BW1_CEB: "backWash1_CEB",
  t_BW2_CEB: "backWash2_CEB",
  t_BW1: "backWash1_backWash",
  t_BW2: "backWash2_backWash",
  t_FF: "forwardFlush_backWash",
  t_BW1_CEBrinse: "bwRinseDrainTop",
  t_BW2_CEBrinse: "bwRinseDrainBottom",
  t_valve: "valveOpenCloseDuration",
};

export const STREAMDATA_FIELDS_MAPPING = {
  Feed_acid_pH: "pH",
  Temp_min: "tempMin",
  Temp_max: "tempMax",
  Temp_BW: "tempDesign",
  Temp_CEB: "tempDesign",
  Temp_design: "tempDesign",
};

export const STANDARD_FIELDS_MAPPING = {
  Flow_FF2: "flow_FF2",
  Flow_FF3: "flow_FF3",
  Flow_FF4: "flow_FF4",
  t_interval_CIP: "cIP",
  N_BW_CIP: "bWStepInCIP",
  f_FTL: "fTLDisplacement",
  BW_ox_conc: "oxidantDosage",
  Flag_CIP: "uFCIPWaterTypeID",
  CEB2_ox_conc: "oxidantValue",
  Flag_CEB: "uFCEBWaterTypeID",
  N_BW_Rinse_CIP: "rinseBWCycle",
  Temp_CIP: "recycleTemperature",
  t_interval_CEB_acid: "acidCEB",
  t_interval_CEB_Oxidant: "disinfectionCEB",
  CIP_ox_conc: "oxidantValue_CIP",
  CIP_SLS_conc: "oxidant2Value_CIP",
  N_Trains_online: "onlineTrains",
  t_interval_AS: "backwash_design",
  t_CIP_recycle: "recycleDuration",
  t_CIP_heat: "heatingStepDuration",
  CEB1_org_acid_conc: "organicValue",
  f_BW_tank_feed: "bWTankRefillRate",
  N_valves_per_skid: "valvesPerTrain",
  N_CIP_RScycles: "cIPRinseSoakCycle",
  t_filtration_cycle: "backwash_design",
  CIP_org_acid_conc: "organicValue_CIP",
  N_Trains_Redundant: "redundantTrains",
  N_Modules_per_Train: "modulesPerTrain",
  t_CEB_soak: "chemicalSoakingDuration_CEB",
  t_CIP_soak: "chemicalSoakingDuration_CIP",
  f_Chem_storage_days: "chemicalStorageTime",
  t_interval_CEB_caustic: "alkaliOxidantCEB",
  N_Trains_standby: "redundantStandbyTrains",
  Temp_mCIP: "recycleTemperature_MiniCIP",
  N_BW_mCIP: "bWStepInMiniCIP",
  N_BW_Rinse_mCIP: "rinseBWCycle_MiniCIP",
  Flag_mCIP: "uFMiniCIPWaterTypeID",
  t_mCIP_heat: "heatingStepDuration_MiniCIP",
  t_mCIP_recycle: "recycleDuration_MiniCIP",
  t_mCIP_soak: "chemicalSoakingDuration_MiniCIP",
  N_mCIP_RScycles: "cIPRinseSoakCycle_MiniCIP",
  mCIP_ox_conc: "oxidantValue_MiniCIP",
  t_interval_mCIP: "miniCIP",
  mCIP_org_acid_conc: "organicValue_MiniCIP",
};

export const UF_CONFIG_COMMON_PAYLOAD = {
  method: "default",
  exportReport: 0,
  TechnologyId: 1,
  reportType: 3,
  Recovery_RO: "0",
  Feed_acid_name: "0",
  Feed_acid_conc: "0",
  Feed_coag_name: "0",
  Feed_coag_conc: "0",
  Feed_ox_name: "0",
  Feed_ox_conc: "0",
  Operating_Cost_Prices: "0",
  CEB3_ox_name: "0",
  CEB3_ox_conc: "0",
  Flag_mCIP2_Chem: "false",
  mCIP_SLS_name: "0",
  mCIP_SLS_conc: "0",
  Feed_acid_bulk_conc: "0",
  Feed_acid_density: "0",
  Feed_acid_price: "0",
  Feed_coag_bulk_conc: "0",
  Feed_coag_density: "0",
  Feed_coag_price: "0",
  Feed_ox_bulk_conc: "0",
  Feed_ox_density: "0",
  Feed_ox_price: "0",
  Module_Properties: "0",
  Project_Level_User_Entries: "0",
  Storage_Tank_Parameters: "0",
  t_interval_CEB_Oxidant: "0",
  N_Chem_CEB1: "1",
  N_Chem_CEB2: "1",
  N_Chem_CEB3: "1",
  N_CEB_RScycles: "1",
  Flag_CEB2_Chem: "false",
  Flag_CEB1_Chem: "false",
  Flag_mCIP1_Chem: "false",
  CIP_N_Chem1_Flag: "false",
  CIP_N_Chem2_Flag: "false",
  Additional_Settings_Screen: "false",
};
