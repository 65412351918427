import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { UF_STANDBY_OPTION } from "@features/feedwater/uf/constants/UFConstants";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";

const UFDiagramStaticText = ({ isInge, isCIP }) => {
  const { data: UFData } = useSelector(state => state.UFStore);

  const isFiltrateTank = useMemo(
    () => UFData.uFBWCEBStandbyOptionID === UF_STANDBY_OPTION.FILTRATE_TANK,
    [UFData.uFBWCEBStandbyOptionID],
  );
  const feedValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 2100, y: 4250 } : { x: 2300, y: 3850 }) : { x: 2125, y: 4250 }),
    [isInge, isCIP],
  );
  const bottomDrainPosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5070, y: 4250 } : { x: 5070, y: 3850 }) : { x: 5070, y: 4250 }),
    [isInge, isCIP],
  );
  const filtrateValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 6500, y: 1600 } : { x: 6500, y: 1200 }) : { x: 5500, y: 1300 }),
    [isInge, isCIP],
  );
  const backwashValvePosition = useMemo(
    () => (isInge ? (isCIP ? { x: 5250, y: 1700 } : { x: 5250, y: 1300 }) : { x: 5250, y: 1650 }),
    [isInge, isCIP],
  );
  const topDrainValvePosition = useMemo(() => {
    const data = { x: 9780, y: 1650 };

    if (isInge) {
      data.x = isCIP && !isFiltrateTank ? 9850 : 9780;
      data.y = isFiltrateTank ? (isCIP ? 1675 : 1275) : (isCIP ? 1925 : 1675);
    }

    return data;
  }, [isInge, isCIP, isFiltrateTank]);

  return (
    <>
      <BoldTextElement fill='#454545' {...feedValvePosition}>
        Feed Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545' {...bottomDrainPosition}>
        Bottom Drain Valve
      </BoldTextElement>
      {!isInge && (
        <BoldTextElement fill='#454545' x='3110' y='3800.64'>
          Air Valve
        </BoldTextElement>
      )}
      <BoldTextElement fill='#454545' {...backwashValvePosition}>
        Backwash Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545' {...filtrateValvePosition}>
        Filtrate Valve
      </BoldTextElement>
      <BoldTextElement fill='#454545'>
        <tspan {...topDrainValvePosition}>Top</tspan>
        <tspan x={topDrainValvePosition.x} y={topDrainValvePosition.y + 120}>
          Drain Valve
        </tspan>
      </BoldTextElement>
    </>
  );
};

export default UFDiagramStaticText;
