import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const GAS_METRIC_UNIT = "Nm³/h";

const UFDiagramAirPumpDetails = () => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData, data } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [airScour, setAirScour] = useState(0);
  const [maxAirScourPressure, setMaxAirScourPressure] = useState(0);

  useEffect(() => {
    const selectedUnit = unitConfig.selectedUnits[18];
    let value = calcEngineData.flow_air_peak || 0;

    if (value && selectedUnit !== GAS_METRIC_UNIT) {
      value = unitConversionByName(value, selectedUnit, GAS_METRIC_UNIT);
    }
    value = Math.round(value);
    setMaxAirScourPressure(`@ ${data.maxAirScourPressure} ${unitConfig.selectedUnits[3]}`);
    setAirScour(`${value} ${selectedUnit}`);
  }, [unitConfig.selectedUnits, calcEngineData]);

  return (
    <>
      <BoldTextElement x='2950' y='2900'>
        Air Scour
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x='2950' y='3025'>
          {airScour}
        </tspan>
        <tspan x='2950' y='3150'>
          {maxAirScourPressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramAirPumpDetails;
