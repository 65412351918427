import { useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";
import useIXTechnologyStrings from "@hooks/useIXTechnologyStrings";
import useUnitConversion from "@hooks/useUnitConversion";

import ConfirmationPopup from "@common/ConfirmationPopup";
import InfoIcon from "@common/icons/InfoIcon";
import MandatoryFieldIcon from "@common/icons/MandatoryFieldIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import { colors } from "@common/styles/Theme";

import { ALERT_MSG, selectedResinList } from "../constants/IXDConstants";
import { regenerationDefaultValue } from "../IXConfiguration/IXhelper";
import {
  updateExistingNew,
  updateIXStore,
  updateResinName1,
  updateResinName2,
  updateResinName3,
  updateResinName4,
  updateViewReport,
} from "../IXDSlice";

const Demineralization = () => {
  const demineralization = useSelector(state => state.IXStore.Demineralization);
  const { updateParameterSettings } = useFPAValidation();
  const ixStore = useSelector(state => state.IXStore.data);
  const IXStrings = useIXTechnologyStrings();
  const [popuConformModal, setpopuConformModal] = useState(false);
  const [lblMessage, setlabelMesg] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);
  const { selectedProcessID } = useSelector(state => state.IXStore.data);
  const selectedUnits = useSelector(state => state.projectInfo?.projectConfig?.unitConfig?.selectedUnits);

  const designTemp = useSelector(
    state => state?.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign,
  );
  const { unitConversionByName } = useUnitConversion();

  const dispatch = useDispatch();

  const handleMouseLeave = () => {};

  const onDemineralizationChange = ({ target: { name, value } }) => {
    const { selectedProcessID } = ixStore;

    if (selectedProcessID !== value) {
      setpopuConformModal(true);
      setUpdatedValue(value);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      dispatch(updateIXStore({ ...ixStore, [name]: parseFloat(value) }));
    }
  };

  const ConformationPopupCancel = result => {
    setpopuConformModal(false);
    if (result === "OK") {
      const designTempInCurrentUnit = unitConversionByName(designTemp, selectedUnits[2], UNITS.celsius);
      const listRegenConds = regenerationDefaultValue(designTempInCurrentUnit);
      dispatch(updateViewReport("false"));
      dispatch(updateExistingNew("true"));
      dispatch(updateResinName1(null));
      dispatch(updateResinName2(null));
      dispatch(updateResinName3(null));
      dispatch(updateResinName4(null));
      dispatch(
        updateIXStore({
          ...ixStore,
          vessel1: null,
          vessel2: null,
          vessel3: null,
          vessel4: null,
          degasifation_ind: false,
          selectedEffluent: null,
          effluentValue: null,
          location: null,
          selectedResinList: selectedResinList,
          listRegenConds: listRegenConds,
          selectedProcessID: parseInt(updatedValue),
        }),
      );
      updateParameterSettings();
    }
  };

  return (
    <StyledCard className='demineralization-card'>
      {<ConfirmationPopup show={popuConformModal} onHide={ConformationPopupCancel} lblMessage={lblMessage} />}
      <Card.Header>
        <CustomHeading
          fontFamily='NotoSansRegular'
          fontSize='14px'
          fontWeight='400'
          color={colors.PrimaryDarkAquaMarine}
          label={IXStrings.demineralization}
          mandatoryIcon={<MandatoryFieldIcon />}
        />
        <Card.Title onMouseLeave={handleMouseLeave}>
          <IconWithTooltip label='Choose appropriate sub-process for this icon (if appropriate).' icon={<InfoIcon />} />
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className='demineralization-radio'>
          {demineralization?.map(data => (
            <div key={data.ixProcessID}>
              <CustomRadio
                key={data.ixProcessID}
                type='radio'
                id='ixDemRadio'
                value={data.ixProcessID}
                name='selectedProcessID'
                onChange={onDemineralizationChange}
                label={data.ixProcessName}
                checked={selectedProcessID === data.ixProcessID}
                className='ix_initialization_radio'
              />
            </div>
          ))}
        </div>
      </Card.Body>
    </StyledCard>
  );
};

export default Demineralization;
