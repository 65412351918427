import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { UF_SPECIAL_FEATURE } from "@features/feedwater/uf/constants/UFConstants";

import UFDiagramAirPumpDetails from "./UFDiagramAirPumpDetails";
import UFDiagramBWCEBChemDetails from "./UFDiagramBWCEBChemDetails";
import UFDiagramBWCEBPumpDetails from "./UFDiagramBWCEBPumpDetails";
import UFDiagramCIPPumpDetails from "./UFDiagramCIPPumpDetails";
import UFDiagramDegasDetails from "./UFDiagramDegasDetails";
import UFDiagramDesignCycleIntervals from "./UFDiagramDesignCycleIntervals";
import UFDiagramFeedPumpDetails from "./UFDiagramFeedPumpDetails";
import UFDiagramFeedWaterDetails from "./UFDiagramFeedWaterDetails";
import UFDiagramFiltrateDetails from "./UFDiagramFIltrateDetails";
import UFDiagramModuleDetails from "./UFDiagramModuleDetails";
import UFDiagramStaticText from "./UFDiagramStaticText";
import UFDiagramStrainerDetails from "./UFDiagramStrainerDetails";
import UFDiagramTankBackWashDetails from "./UFDiagramTankBackWashDetails";
import UFDiagramTankCIPDetails from "./UFDiagramTankCIPDetails";
import UFDiagramTankFiltrateDetails from "./UFDiagramTankFiltrateDetails";
import UFDiagramWasteWaterDetails from "./UFDiagramWasteWaterDetails";

const UFDiagramText = () => {
  const { data, ufCompany } = useSelector(state => state.UFStore);
  const [isBWOnlyDiagram, setIsBWOnlyDiagram] = useState(false);

  const isInge = useMemo(() => ufCompany?.toLowerCase() === "inge", [ufCompany]);
  const isCIP = useMemo(() => data.cIP > 0, [data.cIP]);

  const isOnlyMiniCIP = useMemo(
    () => data.ufSpecialFeatureID === UF_SPECIAL_FEATURE.isMiniCipOnly,
    [data.ufSpecialFeatureID],
  );

  useEffect(() => setIsBWOnlyDiagram(data.uFBWCEBStandbyOptionID === 2), [data.uFBWCEBStandbyOptionID]);

  return (
    <>
      <UFDiagramTankCIPDetails isInge={isInge} isCIP={isCIP} />
      {isBWOnlyDiagram ? (
        <UFDiagramTankBackWashDetails isInge={isInge} isCIP={isCIP} />
      ) : (
        <UFDiagramTankFiltrateDetails isInge={isInge} isCIP={isCIP} />
      )}
      {/* Module Details */}
      <UFDiagramModuleDetails />
      {/* Feed Water Details */}
      <UFDiagramFeedWaterDetails isInge={isInge} isCIP={isCIP} />
      {/* Feed Pump Details */}
      <UFDiagramFeedPumpDetails isInge={isInge} isCIP={isCIP} />
      {/* CIP Pump Details*/}
      {(!isInge || isCIP) && <UFDiagramCIPPumpDetails />}
      {/* Air Scour Details*/}
      {ufCompany?.toLowerCase() === "dupont" && <UFDiagramAirPumpDetails />}
      {/* Strainer Details */}
      <UFDiagramStrainerDetails isInge={isInge} isCIP={isCIP} />
      {/* Static Texts */}
      <UFDiagramStaticText isInge={isInge} isCIP={isCIP} />
      {/* Chemical/Degas (UF Special Features)*/}
      <UFDiagramDegasDetails isInge={isInge} isCIP={isCIP} />
      {/* Backwash Pump Details, @2.5 bar will remain static */}
      {!isOnlyMiniCIP && <UFDiagramBWCEBPumpDetails isInge={isInge} isCIP={isCIP} />}
      {/* Backwash/CEB Chemical Details */}
      <UFDiagramBWCEBChemDetails isInge={isInge} isCIP={isCIP} />
      {/* Net and Gross Filterate */}
      <UFDiagramFiltrateDetails isInge={isInge} isCIP={isCIP} />
      {/* Waste Water Details */}
      <UFDiagramWasteWaterDetails isInge={isInge} isCIP={isCIP} />
      {/* Design Cycle Intervals */}
      <UFDiagramDesignCycleIntervals isInge={isInge} isCIP={isCIP} />
    </>
  );
};

export default UFDiagramText;
