import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import { SYSTEM_DESIGN_WATER } from "@features/feedwater/systemdesign/constants/SystemDesignConstant";
import { UF_STANDBY_OPTION } from "@features/feedwater/uf/constants/UFConstants";
import { getFiltrateValue } from "@features/feedwater/uf/UFConfiguration/UFHelper";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";

const UFDiagramFiltrateDetails = ({ isInge, isCIP }) => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData, data: UFData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);

  const [filtrateData, setFilterateData] = useState({});

  useEffect(() => {
    const data = {
      net: getFiltrateValue(SYSTEM_DESIGN_WATER.PRODUCT_WATER, calcEngineData, unitConfig, unitConversionByName),
      gross: getFiltrateValue(SYSTEM_DESIGN_WATER.GROSS_WATER, calcEngineData, unitConfig, unitConversionByName),
    };
    setFilterateData(data);
  }, [unitConfig.selectedUnits, calcEngineData]);

  const isFiltrateTank = useMemo(
    () => UFData.uFBWCEBStandbyOptionID === UF_STANDBY_OPTION.FILTRATE_TANK,
    [UFData.uFBWCEBStandbyOptionID],
  );

  /**
   * This useMemo hook calculates the x and y positions for net filtrate based on the 
   * conditions of isInge, isCIP, and isFiltrateTank.
   * 
   * - It initializes default values for x and y.
   * - If isInge is true:
   *   - Sets x to 9750.
   *   - Sets y based on the following conditions:
   *     - If isFiltrateTank is true:
   *       - If isCIP is true, sets y to 3250.
   *       - Otherwise, sets y to 2900.
   *     - If isFiltrateTank is false:
   *       - If isCIP is true, sets y to 1150.
   *       - Otherwise, sets y to 750.
   * - If isInge is false:
   *   - Sets y based on the following conditions:
   *     - If isFiltrateTank is true, sets y to 3250.
   *     - Otherwise, sets y to 750.
   * 
   * Finally, it returns the calculated x and y positions.
   */
  const netFiltrateXYPos = useMemo(() => {
    const data = { x: 9700, y: 0 };

    if (isInge) {
      data.x = 9750;
      data.y = isFiltrateTank ? (isCIP ? 3250 : 2900) : (isCIP ? 1150 : 750);
    } else {
      data.y = isFiltrateTank ? 3250 : 750;
    }

    return data;
  }, [isInge, isCIP, isFiltrateTank]);

  /**
   * This useMemo hook calculates the x and y positions for gross filtrate based on the 
   * conditions of isInge and isCIP.
   * 
   * - If isInge is true:
   *   - If isCIP is true, sets x to 6490 and y to 1000.
   *   - Otherwise, sets x to 6490 and y to 600.
   * - If isInge is false:
   *   - Sets x to 6490 and y to 750.
   * 
   * Finally, it returns the calculated x and y positions.
   */
  const grossFiltrateXYPos = useMemo(
    () => (isInge ? (isCIP ? { x: 6490, y: 1000 } : { x: 6490, y: 600 }) : { x: 6490, y: 750 }),
    [isInge, isCIP],
  );

  return (
    <>
      <BoldTextElement>
        <tspan {...grossFiltrateXYPos}>Gross Filtrate</tspan>
        <tspan x={netFiltrateXYPos.x} y={netFiltrateXYPos.y}>
          Net Filtrate
        </tspan>
      </BoldTextElement>
      {/* Gross Filterate */}
      <TextElement fill='#454545' fontSize='120'>
        <tspan x={grossFiltrateXYPos.x} y={grossFiltrateXYPos.y + 140}>
          {filtrateData.gross}
        </tspan>
      </TextElement>
      {/* Net Filtrate */}
      <TextElement fill='#454545'>
        <tspan x={netFiltrateXYPos.x} y={netFiltrateXYPos.y + 140}>
          {filtrateData.net}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramFiltrateDetails;
