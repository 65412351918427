import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

export const ApiDataSlice = createSlice({
  name: "apiData",
  initialState,
  reducers: {
    setApiData: (state, action) => {
      const { key, data } = action.payload;
      state[key] = data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setApiData } = ApiDataSlice.actions;

export default ApiDataSlice.reducer;
