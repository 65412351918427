import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useFPAValidation from "@hooks/useFPAValidation";
import useUnitConversion from "@hooks/useUnitConversion";

import DupontLogger from "@utils/DupontLogger";

import ConfirmationPopup from "@common/ConfirmationPopup";
import InfoIcon from "@common/icons/InfoIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";
import { colors } from "@common/styles/Theme";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";
import SmallLoader from "@common/utils/SmallLoader";

import { useLazyGetAllDataQuery } from "@services/apiConfig";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import {
  ALERT_MSG,
  CONDUCTIVITY_INIT_VAL,
  DEFAULT_REGENERANT_DOSE_VAL3,
  SAC_SAFETY_FACTOR,
  SBA_SAFETY_FACTOR,
  selectedResinList,
  TECH_IXMB,
  WAC_OVERRUN,
  WAC_SAFETY_FACTOR,
  WBA_OVERRUN,
  WBA_SAFETY_FACTOR,
} from "./constants/IXDConstants";
import { regenerationDefaultValue } from "./IXConfiguration/IXhelper";
import {
  setValidDesignID,
  updateExistingNew,
  updateIXStore,
  updateResinInertNameCalc,
  updateResinIonicCalc,
  updateResinName1,
  updateResinName2,
  updateResinName3,
  updateResinName4,
  updateResinNameCalc,
  updateSelectedAnionResin,
  updateSelectedCationResin,
  updateVessel,
  updateVesselFlags,
  updateViewReport,
} from "./IXDSlice";
import { getDefaultOnVessel1Selected, getDefaultProcessID } from "./IXDUtilityFunction";
import SystemDiagram from "./SystemDiagram";
import VesselRegenerationSystemStyled from "./VesselRegenerationSystemStyled";

const VesselRegenerationSystem = () => {
  const dispatch = useDispatch();
  const Logger = DupontLogger("VesselRegenerationSystem");
  const { updateParameterSettings } = useFPAValidation();
  const [getIXVessel, responseIXVessel] = useLazyGetAllDataQuery();
  const [getRegeneration, responseRegeneration] = useLazyGetAllDataQuery();
  const [loader, setloader] = useState(false);
  const [vesselData, setvesselData] = useState();
  const [anionCationFlag, setAnionCationFlag] = useState(false);
  const [cation, setcation] = useState("");
  const [anion, setanion] = useState("");
  const [vesselflag1, setvesselflag1] = useState(true);
  const [vesselflag2, setvesselflag2] = useState(true);
  const [vesselflag3, setvesselflag3] = useState(true);
  const [vesselflag4, setvesselflag4] = useState(true);
  const [defaultAfterCation, setdefaultAfterCation] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const [isSumValid, setIsSumValid] = useState(false);
  const [message, setMessage] = useState("");
  const ixStore = useSelector(state => state.IXStore.data);
  const ixStoreObj = useSelector(state => state.IXStore);
  const VesselStore = useSelector(state => state.IXStore.vesselData);
  const UserInfoStore = useSelector(state => state.userInfo.data);
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const [popuConformModal, setpopuConformModal] = useState(false);
  const [lblMessage, setlabelMesg] = useState(null);
  const [cationTargetName, setcationTargetName] = useState(null);
  const [cationTargetValue, setcationTargetValue] = useState(null);
  const [AnionTargetName, setAnionTargetName] = useState(null);
  const [AnionTargetValue, setAnionTargetValue] = useState(null);
  const [CationAnionTargetValue1, setCationAnionTargetValue1] = useState(null);

  const [VesselTargetName1, setVesselTargetName1] = useState(null);
  const [VesselTargetName2, setVesselTargetName2] = useState(null);
  const [VesselTargetName3, setVesselTargetName3] = useState(null);
  const [VesselTargetName4, setVesselTargetName4] = useState(null);

  const [VesselTargetValue1, setVesselTargetValue1] = useState(null);
  const [VesselTargetValue2, setVesselTargetValue2] = useState(null);
  const [VesselTargetValue3, setVesselTargetValue3] = useState(null);
  const [VesselTargetValue4, setVesselTargetValue4] = useState(null);
  const [flagvessel, setflagvessel] = useState(null);

  const [Res_AndRegenerantDose, setRes_AndRegenerantDose] = useState([]);

  const [activeflag, setactiveflag] = useState();
  const selectedProcessId = useSelector(state => state.IXStore.data.selectedProcessID);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { unitConversionByName } = useUnitConversion();
  const designTemp = useSelector(
    state => state?.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]?.tempDesign,
  );

  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const cationAdvRegen = useSelector(state => state.IXStore?.updateCationDataAdvRegen);
  const [getAndRegenerantDose, responseAndRegenerantDose] = useLazyGetAllDataQuery();
  const anionAdvRegen = useSelector(state => state.IXStore?.updateAnionDataAdvRegen);
  const treatmentType = useSelector(state => state.IXStore.data.treatmentName);
  const MAX_CO2 = unitConversionByName(1000000, unit.selectedUnits[6], UNITS.MicroAtmosphere);
  const MIN_CO2 = unitConversionByName(1, unit.selectedUnits[6], UNITS.MicroAtmosphere);
  const isIXMB = treatmentType === "IXMB";

  const vesselIds = {
    userID: UserInfoStore && UserInfoStore.UserId,
    projectID: ProjectInfoStore && ProjectInfoStore.projectID,
    selectedprocessid: selectedProcessId || getDefaultProcessID(treatmentType),
    processID: selectedProcessId || 7,
  };

  useEffect(() => {
    dispatch(
      updateVesselFlags({
        vesselflag1,
        vesselflag2,
        vesselflag3,
        vesselflag4,
      }),
    );
  }, [vesselflag1, vesselflag2, vesselflag3, vesselflag4]);

  useEffect(() => {
    try {
      ixStore?.vessel1 !== null ? setvesselflag1(false) : setvesselflag1(true);
      ixStore?.vessel2 !== null ? setvesselflag2(false) : setvesselflag2(true);
      ixStore?.vessel3 !== null ? setvesselflag3(false) : setvesselflag3(true);
      ixStore?.vessel4 !== null ? setvesselflag4(false) : setvesselflag4(true);
      const apiUrl = `${"ix/api/v1/VesselRegenerationSystem"}?userID=${vesselIds.userID}&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&selectedprocessid=${selectedProcessId}`;
      getIXVessel(apiUrl);
    } catch {
      Logger.log("Error: Fetch VesselRegenertion data");
    }
    const apiVesselUrl = `${"ix/api/v1/ResinSelectionChanged"}?userID=${vesselIds.userID}&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&processID=${vesselIds.processID}&resinarrangmentID1=${ixStore?.cationResin}&resinarrangmentID2=${ixStore?.anionResin}`;
    if (ixStore?.cationResin != null || ixStore?.anionResin != null) {
      getRegeneration(apiVesselUrl);
    }
  }, []);

  useEffect(() => {
    if (responseIXVessel.isSuccess) {
      setvesselData(responseIXVessel.data);
    }
  }, [responseIXVessel]);

  useEffect(() => {
    if (vesselData && ixStore?.cationResin === null && ixStore.treatmentName === TECH_IXMB) {
      setcationTargetValue(9); // Setting defaults for IXMB cation and anion
      setAnionTargetValue(9);
      setCationAnionTargetValue1(9);
      CationAnionFunc(9);
      const apiVesselUrl = `${"ix/api/v1/ResinSelectionChanged"}?userID=${vesselIds.userID}&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&processID=${vesselIds.processID}&resinarrangmentID1=9&resinarrangmentID2=9`;
      getRegeneration(apiVesselUrl);
    }
  }, [vesselData]);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsSumValid(false);
    }
  };
  const ConformationPopupCancel = RetunValue => {
    setpopuConformModal(false);
    if (RetunValue === "OK") {
      if (activeflag === "Cation") {
        CationFunc(null, null);
      } else if (activeflag === "Anion") {
        AnionFunc(null, null);
      } else if (activeflag === "CationAnion") {
        CationAnionFunc(null);
      } else {
        if (flagvessel === 1) {
          VesselFunc(VesselTargetName1, VesselTargetValue1);
        } else if (flagvessel === 2) {
          VesselFunc(VesselTargetName2, VesselTargetValue2);
        } else if (flagvessel === 3) {
          VesselFunc(VesselTargetName3, VesselTargetValue3);
        } else if (flagvessel === 4) {
          VesselFunc(VesselTargetName4, VesselTargetValue4);
        }
      }
    }
  };
  const CationAnionFunc = value => {
    let CationAnionTargetValue;
    const designTempInCurrentUnit = unitConversionByName(designTemp, unit.selectedUnits[2], UNITS.celsius);
    const listRegenConds = regenerationDefaultValue(designTempInCurrentUnit);
    setAnionCationFlag(true);
    dispatch(updateViewReport("false"));
    dispatch(updateExistingNew("true"));
    if (CationAnionTargetValue1 == null) {
      CationAnionTargetValue = value;
    } else {
      CationAnionTargetValue = CationAnionTargetValue1;
    }
    if (CationAnionTargetValue != null && vesselData.resinCollectionVM3) {
      let selectionValue = vesselData.resinCollectionVM3?.filter(
        data => data.ixResinArrangmentID == CationAnionTargetValue,
      );
      selectionValue = selectionValue[0].resinArrangmentName;
      const parts = selectionValue.replace(/[\s[\]]/g, "").split(/[-|]/);
      let data1 = parts[0].trim();
      let data2 = parts.length > 1 ? parts[1].trim() : null;
      if (data1.length > 3) {
        data2 = data1.slice(-3);
        data1 = data1.slice(0, 3);
      }
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(data2));
      dispatch(updateResinName3(null));
      dispatch(updateResinName4(null));
      dispatch(updateSelectedCationResin(selectionValue));
      dispatch(updateSelectedAnionResin(null));
      dispatch(
        updateIXStore({
          ...ixStore,
          ["cationResin"]: parseInt(CationAnionTargetValue),
          ["anionResin"]: parseInt(CationAnionTargetValue),
          ["vessel1"]: null,
          ["vessel2"]: null,
          ["vessel3"]: null,
          ["vessel4"]: null,
          ["degasifation_ind"]: false,
          ["selectedEffluent"]: null,
          ["effluentValue"]: null,
          ["location"]: null,
          selectedResinList: selectedResinList,
          listRegenConds: listRegenConds,
        }),
      );
      dispatch(
        updateResinNameCalc({
          WAC: null,
          WBA: null,
          SAC: null,
          SBA: null,
        }),
      );
      dispatch(
        updateResinInertNameCalc({
          Inert1: null,
          Inert2: null,
        }),
      );
      dispatch(
        updateResinIonicCalc({
          WACIon: 0,
          WBAIon: 0,
          SACIon: 0,
          SBAIon: 0,
        }),
      );
      updateParameterSettings();
      const apiVesselUrl = `${"ix/api/v1/ResinSelectionChanged"}?userID=${vesselIds.userID}&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&processID=${vesselIds.processID}&resinarrangmentID1=${CationAnionTargetValue}&resinarrangmentID2=${CationAnionTargetValue}`;
      if (cationTargetValue != 0 && AnionTargetValue != 0) {
        getRegeneration(apiVesselUrl);
      }
    }
  };

  const handleAnionCation = e => {
    setcationTargetValue(e.target.value);
    setAnionTargetValue(e.target.value);
    setCationAnionTargetValue1(e.target.value);
    if (ixStore?.cationResin != null && ixStore?.anionResin != null) {
      setactiveflag("CationAnion");
      setpopuConformModal(true);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      CationAnionFunc(e.target.value);
    }
    updateParameterSettings();
  };
  const CationFunc = (ename, evalue) => {
    let cation_name, cation_value;
    const designTempInCurrentUnit = unitConversionByName(designTemp, unit.selectedUnits[2], UNITS.celsius);

    const listRegenConds = regenerationDefaultValue(designTempInCurrentUnit);

    if (cationTargetName == null) {
      cation_name = ename;
    } else {
      cation_name = cationTargetName;
    }
    if (cationTargetValue == null) {
      cation_value = evalue;
    } else {
      cation_value = cationTargetValue;
    }

    if (anionCationFlag === true) {
      dispatch(updateResinName1(null));
      dispatch(updateResinName2(null));
      dispatch(updateResinName3(null));
      dispatch(updateResinName4(null));
      setAnionCationFlag(false);
    }
    setloader(true);
    setcation(cation_value);
    dispatch(updateViewReport("false"));
    dispatch(updateExistingNew("true"));
    let selectionValue = vesselData.resinCollectionVM1.filter(data => data.ixResinArrangmentID == cation_value);
    if (selectionValue.length <= 0) {
      return;
    }
    selectionValue = selectionValue[0].resinArrangmentName;
    const parts = selectionValue.replace(/[\s[\]]/g, "").split(/[-|]/);
    let data1 = parts[0].trim();
    let data2 = parts.length > 1 ? parts[1].trim() : null;
    if (data1.length > 3) {
      data2 = data1.slice(-3);
      data1 = data1.slice(0, 3);
    }
    if (data2 === null && ixStoreObj.resinName2 === null) {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(ixStoreObj.resinName3));
      dispatch(updateResinName3(ixStoreObj.resinName4));
      dispatch(updateResinName4(null));
    } else if (
      data2 === null &&
      ixStoreObj.resinName4 === null &&
      ixStoreObj.resinName3 !== null &&
      (ixStore.anionResin === 4 || ixStore.anionResin === 1)
    ) {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(ixStoreObj.resinName3));
      dispatch(updateResinName3(ixStoreObj.resinName4));
    } else if (data2 === null && ixStoreObj.resinName4 === null) {
      dispatch(updateResinName1(data1));
    } else if (data2 === null && ixStoreObj.resinName4 !== null) {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(ixStoreObj.resinName3));
      dispatch(updateResinName3(ixStoreObj.resinName4));
      dispatch(updateResinName4(null));
    } else if (
      data2 !== null &&
      ixStoreObj.resinName4 === null &&
      ixStoreObj.resinName3 !== null &&
      ixStoreObj.resinName2 !== null &&
      ixStore.anionResin != 4 &&
      ixStore.anionResin != 1
    ) {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(data2));
      dispatch(updateResinName3(ixStoreObj.resinName2));
      dispatch(updateResinName4(ixStoreObj.resinName3));
    } else if (
      (ixStore.anionResin === 4 || ixStore.anionResin === 1) &&
      ixStoreObj.resinName4 === null &&
      ixStoreObj.resinName3 === null
    ) {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(data2));
      dispatch(updateResinName3(ixStoreObj.resinName2));
      dispatch(updateResinName4(ixStoreObj.resinName3));
    } else {
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(data2));
    }
    dispatch(updateSelectedCationResin(selectionValue));
    dispatch(
      updateIXStore({
        ...ixStore,
        [cation_name]: parseInt(cation_value),
        ["vessel1"]: null,
        ["vessel2"]: null,
        ["vessel3"]: null,
        ["vessel4"]: null,
        ["degasifation_ind"]: false,
        ["selectedEffluent"]: null,
        ["effluentValue"]: null,
        ["location"]: null,
        selectedResinList: selectedResinList,
        listRegenConds: listRegenConds,
      }),
    );
    dispatch(
      updateResinNameCalc({
        WAC: null,
        WBA: null,
        SAC: null,
        SBA: null,
      }),
    );
    dispatch(
      updateResinInertNameCalc({
        Inert1: null,
        Inert2: null,
      }),
    );
    dispatch(
      updateResinIonicCalc({
        WACIon: 0,
        WBAIon: 0,
        SACIon: 0,
        SBAIon: 0,
      }),
    );
    updateParameterSettings();
    if (anion != "0" || ixStore?.anionResin != 0) {
      const apiVesselUrl = `${"ix/api/v1/ResinSelectionChanged"}?userID=${
        vesselIds.userID
      }&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&processID=${
        vesselIds.processID
      }&resinarrangmentID1=${cation_value}&resinarrangmentID2=${anion != "" ? anion : ixStore?.anionResin}`;
      getRegeneration(apiVesselUrl);
    }
  };
  const AnionFunc = (ename, evalue) => {
    let anion_name, anion_value;
    const designTempInCurrentUnit = unitConversionByName(designTemp, unit.selectedUnits[2], UNITS.celsius);
    const listRegenConds = regenerationDefaultValue(designTempInCurrentUnit);
    if (AnionTargetName === null) {
      anion_name = ename;
    } else {
      anion_name = AnionTargetName;
    }
    if (AnionTargetValue === null) {
      anion_value = evalue;
    } else {
      anion_value = AnionTargetValue;
    }
    setloader(true);
    if (anionCationFlag === true) {
      dispatch(updateResinName1(null));
      dispatch(updateResinName2(null));
      dispatch(updateResinName3(null));
      dispatch(updateResinName4(null));
      setAnionCationFlag(false);
    }
    dispatch(updateViewReport("false"));
    dispatch(updateExistingNew("true"));
    setanion(anion_value);
    let selectionValue = vesselData.resinCollectionVM2.filter(data => data.ixResinArrangmentID == anion_value);
    if (selectionValue.length <= 0) {
      return;
    }
    selectionValue = selectionValue[0].resinArrangmentName;
    const parts = selectionValue.replace(/[\s[\]]/g, "").split(/[-|]/);
    let data1 = parts[0].trim();
    let data2 = parts.length > 1 ? parts[1].trim() : null;
    if (data1.length > 3) {
      data2 = data1.slice(-3);
      data1 = data1.slice(0, 3);
    }
    if (ixStore.resinName2 === null || ixStore.cationResin === 2 || ixStore.cationResin === 3) {
      dispatch(updateResinName2(data1));
      dispatch(updateResinName3(data2));
    } else {
      dispatch(updateResinName3(data1));
      dispatch(updateResinName4(data2));
    }
    dispatch(updateSelectedAnionResin(selectionValue));
    dispatch(
      updateIXStore({
        ...ixStore,
        [anion_name]: parseInt(anion_value),
        ["vessel1"]: null,
        ["vessel2"]: null,
        ["vessel3"]: null,
        ["vessel4"]: null,
        ["degasifation_ind"]: false,
        ["selectedEffluent"]: null,
        ["effluentValue"]: null,
        ["location"]: null,
        selectedResinList: selectedResinList,
        listRegenConds: listRegenConds,
      }),
    );
    dispatch(
      updateResinNameCalc({
        WAC: null,
        WBA: null,
        SAC: null,
        SBA: null,
      }),
    );
    dispatch(
      updateResinInertNameCalc({
        Inert1: null,
        Inert2: null,
      }),
    );
    dispatch(
      updateResinIonicCalc({
        WACIon: 0,
        WBAIon: 0,
        SACIon: 0,
        SBAIon: 0,
      }),
    );
    updateParameterSettings();

    if (cation != "0" || ixStore?.cationResin != 0) {
      const apiVesselUrl = `${"ix/api/v1/ResinSelectionChanged"}?userID=${
        vesselIds.userID
      }&projectID=${vesselIds.projectID}&ixTreatment=${treatmentType}&processID=${vesselIds.processID}&resinarrangmentID1=${
        cation != "" ? cation : ixStore?.cationResin
      }&resinarrangmentID2=${anion_value}`;
      getRegeneration(apiVesselUrl);
    }
  };
  const handleCationData = e => {
    setvesselflag1(true);
    setcationTargetName(e.target.name);
    setcationTargetValue(e.target.value);
    if (ixStore?.cationResin != null) {
      setactiveflag("Cation");
      setpopuConformModal(true);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      CationFunc(e.target.name, e.target.value);
    }
    updateParameterSettings();
  };

  const handleAnionData = e => {
    setAnionTargetName(e.target.name);
    setAnionTargetValue(e.target.value);
    if (ixStore?.anionResin != null) {
      setactiveflag("Anion");
      setpopuConformModal(true);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      AnionFunc(e.target.name, e.target.value);
    }
    updateParameterSettings();
  };
  const VesselFunc = (ename, evalue) => {
    const vessel_name = ename;
    const vessel_value = evalue;
    const designTempInCurrentUnit = unitConversionByName(designTemp, unit.selectedUnits[2], UNITS.celsius);
    const listRegenConds = regenerationDefaultValue(designTempInCurrentUnit);
    let vesselCoCurrent = false;
    let hardnes = false;
    if (ixStore.vessel1 == 0 || ixStore.vessel2 == 0 || ixStore.vessel3 == 0 || ixStore.vessel4 == 0) {
      vesselCoCurrent = true;
    }
    if (
      (ixStore?.cationResin == 3 && ixStore?.anionResin == 4) ||
      (ixStore?.cationResin == 3 && ixStore?.anionResin == 1) ||
      (ixStore?.cationResin == 3 && ixStore?.anionResin == 11) ||
      (ixStore?.cationResin == 3 && ixStore?.anionResin == 12) ||
      (ixStore?.cationResin == 3 && ixStore?.anionResin == 13)
    ) {
      hardnes = true;
    }

    const hasBothSafetyFactors = (array, factor1, factor2) =>
      array.some(element => element.langText === factor1) && array.some(element => element.langText === factor2);
    const cationHasBothSafetyFactors = hasBothSafetyFactors(
      Res_AndRegenerantDose.ixRegenerationDoseName_column1,
      SAC_SAFETY_FACTOR,
      WAC_SAFETY_FACTOR,
    );
    const anionHasBothSafetyFactors = hasBothSafetyFactors(
      Res_AndRegenerantDose?.ixRegenerationDoseName_column2,
      SBA_SAFETY_FACTOR,
      WBA_SAFETY_FACTOR,
    );

    let Cation_saftyFactorVal = 0,
      Cation_regeneratDoseVal1 = 0,
      Cation_regenerantDoseVal2 = 0,
      Cation_regenerantDoseVal3 = 0;
    if (Res_AndRegenerantDose?.ixRegenerationDoseName_column1?.length > 0) {
      Res_AndRegenerantDose?.ixRegenerationDoseName_column1?.forEach(element => {
        switch (element.langText) {
          case SAC_SAFETY_FACTOR:
            Cation_saftyFactorVal = element.defaultValue;
            break;
          case WAC_SAFETY_FACTOR:
            if (cationHasBothSafetyFactors) {
              Cation_regeneratDoseVal1 = element.defaultValue;
            } else {
              Cation_saftyFactorVal = element.defaultValue;
            }
            break;
          case WAC_OVERRUN:
            Cation_regenerantDoseVal2 = element.defaultValue;
            break;
          default:
            Cation_regenerantDoseVal3 = DEFAULT_REGENERANT_DOSE_VAL3;
        }
      });
    }
    ///anion
    let Anion_saftyFactorVal = 0,
      Anion_regeneratDoseVal1 = 0,
      Anion_regenerantDoseVal2 = 0,
      Anion_regenerantDoseVal3 = 0;
    if (Res_AndRegenerantDose?.ixRegenerationDoseName_column2?.length > 0) {
      Res_AndRegenerantDose?.ixRegenerationDoseName_column2?.forEach(element => {
        switch (element.langText) {
          case SBA_SAFETY_FACTOR:
            Anion_saftyFactorVal = element.defaultValue;
            break;
          case WBA_SAFETY_FACTOR:
            if (anionHasBothSafetyFactors) {
              Anion_regeneratDoseVal1 = element.defaultValue;
            } else {
              Anion_saftyFactorVal = element.defaultValue;
            }
            break;
          case WBA_OVERRUN:
            Anion_regenerantDoseVal2 = element.defaultValue;
            break;
          default:
            Anion_regenerantDoseVal3 = DEFAULT_REGENERANT_DOSE_VAL3;
        }
      });
    }
    setanion(vessel_value);
    const { vesselCoCurrentAvg, vesselCoCurrentEnd, vesselOthersAvg, vesselOthersEnd } = CONDUCTIVITY_INIT_VAL;
    dispatch(
      updateIXStore({
        ...ixStore,
        [vessel_name]: parseInt(vessel_value),
        listRegenConds: listRegenConds,
        listProductQualityandregeneration: [
          {
            productQualityDoseID: 0,
            resinRegenerationID: 0,
            speciesLblNameID: 1,
            averageSpeciesVal: isIXMB ? 0.02 : hardnes && vesselCoCurrent ? 1 : vesselCoCurrent ? 100 : 20,
            endpoingSpeciesVal: isIXMB ? 0.04 : hardnes && vesselCoCurrent ? 2 : vesselCoCurrent ? 200 : 40,
            averageConductivityVal: vesselCoCurrent ? vesselCoCurrentAvg : vesselOthersAvg,
            endpointConductivityVal: vesselCoCurrent ? vesselCoCurrentEnd : vesselOthersEnd,
            speciesTwoLblNameID: 0,
            regenerantDoseLbl1ID: 0,
            regeneratDoseVal1: Cation_regeneratDoseVal1,
            regenerantDoseLbl2ID: 0,
            regenerantDoseLbl4ID: ixStore?.newPlant_ind && ixStore?.cationResin === 3 ? 4 : 1,
            regenerantDoseVal2: Cation_regenerantDoseVal2,
            regenerantDoseVal3: Cation_regenerantDoseVal3,
            regenerantDoseVal4: GlobalUnitConversion(
              GlobalUnitConversionStore,
              cationAdvRegen?.typicalValue?.regenerantDoseTypical,
              unit.selectedUnits[14],
              "g/L",
            ),

            overAllEfficiency: 0,
            overAllComputation: 0,
            doseOptimization: 0,
            naturalEffect: 0,
            saftyFactorLbl: 0,
            saftyFactorVal: Cation_saftyFactorVal,
            speciesUnit: isIXMB ? 3 : hardnes ? 4 : 1, ///
            volume: null,
            flowRate: null,
            time: null,
            regenerationRatio: cationAdvRegen?.typicalValue?.regenerantRatioTypical,
          },
          {
            productQualityDoseID: 0,
            resinRegenerationID: 0,
            speciesLblNameID: 0,
            averageSpeciesVal: isIXMB ? 0.02 : vesselCoCurrent ? 100 : 20,
            endpoingSpeciesVal: isIXMB ? 0.04 : vesselCoCurrent ? 200 : 40,
            averageConductivityVal: 0,
            endpointConductivityVal: 70,
            speciesTwoLblNameID: 0,
            regenerantDoseLbl1ID: 0,
            regeneratDoseVal1: Anion_regeneratDoseVal1,
            regenerantDoseLbl2ID: 0,
            regenerantDoseLbl4ID: ixStore?.newPlant_ind && ixStore?.anionResin === 4 ? 4 : 1,
            regenerantDoseVal2: Anion_regenerantDoseVal2,
            regenerantDoseVal3: Anion_regenerantDoseVal3,
            regenerantDoseVal4: GlobalUnitConversion(
              GlobalUnitConversionStore,
              anionAdvRegen?.typicalValue?.regenerantDoseTypical, ///dose
              unit.selectedUnits[14],
              "g/L",
            ),
            overAllEfficiency: 0,
            overAllComputation: 0,
            doseOptimization: 0,
            naturalEffect: 0,
            saftyFactorLbl: 0,
            saftyFactorVal: Anion_saftyFactorVal,
            speciesUnit: isIXMB ? 3 : 1,
            volume: null,
            flowRate: null,
            time: null,
            regenerationRatio: anionAdvRegen?.typicalValue?.regenerantRatioTypical,
          },
        ],
      }),
    );
    updateParameterSettings();
  };

  const handlePoslishVessel = e => {
    const { value } = e.target;
    setcationTargetValue(value);
    setAnionTargetValue(value);
    setCationAnionTargetValue1(value);
    updateParameterSettings();

    if (ixStore?.cationResin != null && ixStore?.anionResin != null) {
      setactiveflag("CationAnion");
      setpopuConformModal(true);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      CationAnionFunc(e.target.value);
    }
  };
  const handleVessel = e => {
    if (e.target.value === "") {
      return;
    }
    if (e.target.name === "vessel1") {
      setflagvessel(1);
      setVesselTargetName1(e.target.name);
      setVesselTargetValue1(e.target.value);
      dispatch(
        updateIXStore({
          ...ixStore,
          ["sacRegenVesselDia"]: unitConversionByName(
            getDefaultOnVessel1Selected(treatmentType, e.target.value),
            unit.selectedUnits[8],
            UNITS.millimeter,
          ),
          ["sbaRegenVesselDia"]: unitConversionByName(
            getDefaultOnVessel1Selected(treatmentType, e.target.value),
            unit.selectedUnits[8],
            UNITS.millimeter,
          ),
          ["backwashTowerDiameter"]: unitConversionByName(
            getDefaultOnVessel1Selected(treatmentType, e.target.value),
            unit.selectedUnits[8],
            UNITS.millimeter,
          ),
        }),
      );
    } else if (e.target.name === "vessel2") {
      setflagvessel(2);
      setVesselTargetName2(e.target.name);
      setVesselTargetValue2(e.target.value);
    } else if (e.target.name === "vessel3") {
      setflagvessel(3);
      setVesselTargetName3(e.target.name);
      setVesselTargetValue3(e.target.value);
    } else {
      setflagvessel(4);
      setVesselTargetName4(e.target.name);
      setVesselTargetValue4(e.target.value);
    }
    if (ixStore[e.target.name] !== null) {
      setactiveflag("vessel");
      setpopuConformModal(true);
      setlabelMesg(ALERT_MSG.RESIN_ARRANGEMENT_CHANGE);
    } else {
      VesselFunc(e.target.name, e.target.value);
    }
    updateParameterSettings();
  };
  useEffect(() => {
    if (responseRegeneration.isSuccess === true) {
      dispatch(updateVessel(responseRegeneration.data));
      dispatch(setValidDesignID(responseRegeneration.data.validDesignID));
      responseRegeneration.data?.vesselResinDetails1?.length > 0 &&
      responseRegeneration.data?.vesselResinDetails1 !== null
        ? setvesselflag1(false)
        : setvesselflag1(true);
      responseRegeneration.data?.vesselResinDetails2?.length > 0 &&
      responseRegeneration.data?.vesselResinDetails2 !== null
        ? setvesselflag2(false)
        : setvesselflag2(true);
      responseRegeneration.data?.vesselResinDetails3?.length > 0 &&
      responseRegeneration.data?.vesselResinDetails3 !== null
        ? setvesselflag3(false)
        : setvesselflag3(true);
      responseRegeneration.data?.vesselResinDetails4?.length > 0 &&
      responseRegeneration.data?.vesselResinDetails4 !== null
        ? setvesselflag4(false)
        : setvesselflag4(true);
      setloader(false);
      try {
        const apiUrl = `${"ix/api/v1/ProductQualityAndRegenerantDose"}?userID=${vesselIds.userID}&projectID=${vesselIds.projectID}&validdesignID=${responseRegeneration.data.validDesignID}&regenerantID=1&treatmentName=${treatmentType}
          &columnNum= 1`;
        getAndRegenerantDose(apiUrl);
      } catch {
        Logger.log("Error: Fetch VesselRegenertion data");
      }
    }
  }, [responseRegeneration]);

  useEffect(() => {
    if (responseAndRegenerantDose.isSuccess === true) {
      setRes_AndRegenerantDose(responseAndRegenerantDose.data);
    }
  }, [responseAndRegenerantDose]);

  const handleDegasification = e => {
    const { name, checked } = e.target;
    if (checked && ixStoreObj.selectedcationResign === "[WAC]" && ixStoreObj.selectedanionResign === "[WBA] - [SBA]") {
      dispatch(
        updateIXStore({
          ...ixStore,
          [name]: checked,
          ["selectedEffluent"]: 3,
          ["effluentValue"]: 10,
          ["location"]: null,
        }),
      );
    } else if (checked) {
      dispatch(
        updateIXStore({
          ...ixStore,
          [name]: checked,
          ["selectedEffluent"]: 3,
          ["effluentValue"]: 10,
          ["location"]: 1,
        }),
      );
    } else {
      dispatch(
        updateIXStore({
          ...ixStore,
          [name]: checked,
          ["selectedEffluent"]: null,
          ["effluentValue"]: null,
          ["location"]: null,
        }),
      );
    }
    updateParameterSettings();
  };

  const EffluentClick = e => {
    let value = 1;
    if (e.target.value == 3) {
      value = 10;
    }
    dispatch(
      updateIXStore({
        ...ixStore,
        ["selectedEffluent"]: parseInt(e.target.value),
        ["effluentValue"]: value,
      }),
    );
    updateParameterSettings();
  };

  const txtChange = e => {
    dispatch(
      updateIXStore({
        ...ixStore,
        ["effluentValue"]: parseFloat(e.target.value),
      }),
    );
    updateParameterSettings();
  };

  const LocationClick = e => {
    if (parseInt(e.target.value) === 0) {
      setdefaultAfterCation(false);
    } else if (parseInt(e.target.value) === 1) {
      setdefaultAfterCation(true);
    } else {
      setdefaultAfterCation(false);
    }
    dispatch(updateIXStore({ ...ixStore, location: parseInt(e.target.value) }));
    updateParameterSettings();
  };

  const handleFocus = id => {
    setIsFocused(id);
  };

  const handleBlur = e => {
    const { name, value } = e.target;
    if (name === "Percent_Removal") {
      if (value < 1 || value > 100 || isNaN(value)) {
        setIsSumValid(true);
        setMessage(
          "The IX Degasification Effluent % Removal is not correctly specified. It should be between 1 and 100%",
        );
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    } else if (name === "CO2_Partial_Pressure") {
      if (value < MIN_CO2 || value > MAX_CO2 || isNaN(value)) {
        setIsSumValid(true);
        setMessage(
          `The IX Degasification Effluent Partial Pressure is not correctly specified. It should be between ${MIN_CO2} and ${MAX_CO2}`,
        );
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    } else if (name === "CO2_Concentration") {
      if (value < 1 || value > 1000000 || isNaN(value)) {
        setIsSumValid(true);
        setMessage(
          "The IX Degasification Effluent Concentration is not correctly specified. It should be between 1 and 1000000",
        );
        setTimeout(() => {
          e.target.focus();
        }, 0);
      }
    }
    setIsFocused(null);
  };
  return (
    <>
      {<ConfirmationPopup show={popuConformModal} onHide={e => ConformationPopupCancel(e)} lblMessage={lblMessage} />}
      <VesselRegenerationSystemStyled className='vessel-regeneration-row'>
        <SystemDiagram />
        <div className='card-container'>
          <StyledCard className='resin-arrangement-column'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Resin Arrangement'
              />
              <Card.Title>
                <IconWithTooltip
                  label='Choose which types of resin will be used and how they are arranged.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            {treatmentType === "IXMB" && (
              <div>
                <Card.Body>
                  <CustomLabel label='Polish Vessel' />
                  <CustomSelect
                    id='formSelect'
                    name='cationResin'
                    value={ixStore?.cationResin}
                    onChange={handlePoslishVessel}
                  >
                    {vesselData &&
                      vesselData?.resinCollectionVM3?.map(
                        vesselCation =>
                          vesselCation.ixResinArrangmentID !== 0 && (
                            <option key={vesselCation.ixResinArrangmentID} value={vesselCation.ixResinArrangmentID}>
                              {vesselCation.resinArrangmentName}
                            </option>
                          ),
                      )}
                  </CustomSelect>
                </Card.Body>
              </div>
            )}
            {treatmentType === "IXD" && (
              <Card.Body>
                <div className='label-select-group'>
                  <CustomLabel label='Cation' />
                  <CustomSelect
                    id='formSelect'
                    name='cationResin'
                    value={ixStore?.cationResin}
                    onChange={handleCationData}
                  >
                    {/* <option value="">Choose Cation</option> */}
                    {vesselData &&
                      vesselData?.resinCollectionVM1?.map(vesselCation => (
                        <option key={vesselCation.ixResinArrangmentID} value={vesselCation.ixResinArrangmentID}>
                          {vesselCation.resinArrangmentName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
                <div className='label-select-group'>
                  <CustomLabel label='Anion' />
                  <CustomSelect
                    id='formSelect1'
                    name='anionResin'
                    value={ixStore?.anionResin}
                    onChange={handleAnionData}
                  >
                    {/* <option value="">Choose Anion</option> */}
                    {vesselData &&
                      vesselData?.resinCollectionVM2?.map(vesselAnion => (
                        <option key={vesselAnion.ixResinArrangmentID} value={vesselAnion.ixResinArrangmentID}>
                          {vesselAnion.resinArrangmentName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
                <p className='or-option'>OR</p>
                <div className='label-select-group'>
                  <CustomLabel label='Cation & Anion Combined' />
                  <CustomSelect
                    name='anionCatainAndResin'
                    value={ixStore?.anionResin === ixStore?.cationResin ? ixStore?.anionResin : ""}
                    onChange={handleAnionCation}
                  >
                    {/* <option value="">Choose Cation & Anion Combined</option> */}
                    {vesselData &&
                      vesselData?.resinCollectionVM3?.map(vesselAnion => (
                        <option
                          key={vesselAnion.ixResinArrangmentID}
                          name='cationandanionResin'
                          value={vesselAnion.ixResinArrangmentID}
                        >
                          {vesselAnion.resinArrangmentName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
              </Card.Body>
            )}
          </StyledCard>

          <StyledCard
            className='regeneration-system-column'
            hidden={vesselflag1 || cationTargetValue == 0 || AnionTargetName == 0}
          >
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Regeneration System'
              />
              <Card.Title>
                <IconWithTooltip
                  label='For each vessel, choose the regeneration scheme that will be used.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            {loader ? (
              <SmallLoader />
            ) : (
              <Card.Body>
                <div>
                  <CustomLabel label='Vessel 1' hidden={vesselflag1} />
                  <CustomSelect name='vessel1' value={ixStore?.vessel1} onChange={handleVessel} hidden={vesselflag1}>
                    <option value=''>Choose Vessel 1</option>
                    {VesselStore &&
                      VesselStore?.vesselResinDetails1?.map(vesselAnion => (
                        <option key={vesselAnion.regenSystemID} name='vessel1' value={vesselAnion.regenSystemID}>
                          {vesselAnion.regenSystemName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
                <div>
                  <CustomLabel label='Vessel 2' hidden={vesselflag2} />
                  <CustomSelect name='vessel2' value={ixStore?.vessel2} onChange={handleVessel} hidden={vesselflag2}>
                    <option value=''>Choose Vessel 2</option>
                    {VesselStore &&
                      VesselStore?.vesselResinDetails2?.map(vesselAnion => (
                        <option key={vesselAnion.regenSystemID} name='vessel2' value={vesselAnion.regenSystemID}>
                          {vesselAnion.regenSystemName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
                <div>
                  <CustomLabel label='Vessel 3' hidden={vesselflag3} />
                  <CustomSelect name='vessel3' value={ixStore?.vessel3} onChange={handleVessel} hidden={vesselflag3}>
                    <option value=''>Choose Vessel 3</option>
                    {VesselStore &&
                      VesselStore?.vesselResinDetails3?.map(vesselAnion => (
                        <option key={vesselAnion.regenSystemID} name='vessel3' value={vesselAnion.regenSystemID}>
                          {vesselAnion.regenSystemName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
                <div>
                  <CustomLabel label='Vessel 4' hidden={vesselflag4} />
                  <CustomSelect name='vessel4' value={ixStore?.vessel4} onChange={handleVessel} hidden={vesselflag4}>
                    <option value=''>Choose Vessel 4</option>
                    {VesselStore &&
                      VesselStore?.vesselResinDetails4?.map(vesselAnion => (
                        <option key={vesselAnion.regenSystemID} name='vessel4' value={vesselAnion.regenSystemID}>
                          {vesselAnion.regenSystemName}
                        </option>
                      ))}
                  </CustomSelect>
                </div>
              </Card.Body>
            )}
          </StyledCard>

          {treatmentType !== TECH_IXMB && (
            <StyledCard
              className='degasification-column'
              hidden={
                vesselflag1 ||
                cationTargetValue == 0 ||
                AnionTargetName == 0 ||
                (ixStore?.cationResin == 9 && ixStore?.anionResin == 9) ||
                (ixStore?.cationResin == 10 && ixStore?.anionResin == 10)
              }
            >
              <Card.Header>
                <CustomHeading
                  fontFamily='NotoSansRegular'
                  fontSize='14px'
                  fontWeight='400'
                  color={colors.PrimaryDarkAquaMarine}
                  label='Degasification'
                />
                <Card.Title className='switch-info'>
                  <ToggleSwitch
                    small
                    id='degasificationSwitch'
                    checked={ixStore.degasifation_ind}
                    name='degasifation_ind'
                    value={ixStore.degasifation_ind}
                    onChange={handleDegasification}
                  />
                  <IconWithTooltip
                    label='If degasification included, choose location of degasifier and determine outlet CO2 concentration.'
                    icon={<InfoIcon />}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className='effluent'>
                  <div className='effluent-heading'>
                    <CustomHeading
                      fontFamily='NotoSansRegular'
                      fontSize='14px'
                      fontWeight='400'
                      color={colors.PrimaryDarkAquaMarine}
                      label='Effluent'
                    />
                    <div className='vertical-line'></div>
                  </div>
                  <div className='co-removal'>
                    <CustomRadio
                      checked={ixStore.selectedEffluent === 1 ? true : false}
                      disabled={ixStore.degasifation_ind ? false : true}
                      type='radio'
                      id='radio1'
                      value={1}
                      name='selectedEffluent'
                      onClick={EffluentClick}
                      label='CO₂ % Removal'
                    />
                    <InputWithText
                      isError={ixStore.effluentValue < 1 || ixStore.effluentValue > 100}
                      isWarning={false}
                      onBlur={handleBlur}
                      onFocus={() => handleFocus(1)}
                      isFocused={isFocused === 1}
                      disabled={ixStore.selectedEffluent === 1 ? false : true}
                      type='number'
                      name='Percent_Removal'
                      value={ixStore.selectedEffluent === 1 ? ixStore.effluentValue : 0}
                      onChange={txtChange}
                      id='co'
                      inputText='%'
                    />
                  </div>
                  <div className='co-pressure'>
                    <CustomRadio
                      disabled={ixStore.degasifation_ind ? false : true}
                      checked={ixStore.selectedEffluent === 2 ? true : false}
                      type='radio'
                      id='radio2'
                      value={2}
                      name='selectedEffluent'
                      onClick={EffluentClick}
                      label='CO₂ Partial Pressure'
                    />
                    <InputWithText
                      isError={ixStore?.effluentValue < MIN_CO2 || ixStore?.effluentValue > MAX_CO2}
                      isWarning={false}
                      onBlur={handleBlur}
                      onFocus={() => handleFocus(1)}
                      isFocused={isFocused === 2}
                      disabled={ixStore.selectedEffluent === 2 ? false : true}
                      type='number'
                      name='CO2_Partial_Pressure'
                      value={ixStore.selectedEffluent === 2 ? ixStore.effluentValue : 0}
                      onChange={txtChange}
                      id='co'
                      inputText={unit.selectedUnits[6]}
                    />
                  </div>
                  <div className='co-concentration'>
                    <CustomRadio
                      disabled={ixStore.degasifation_ind ? false : true}
                      checked={ixStore.selectedEffluent === 3 ? true : false}
                      type='radio'
                      id='radio3'
                      value={3}
                      name='selectedEffluent'
                      onClick={EffluentClick}
                      label='CO₂ Concentration'
                    />
                    <InputWithText
                      isError={ixStore?.effluentValue < 1 || ixStore?.effluentValue > 1000000}
                      isWarning={false}
                      onBlur={handleBlur}
                      onFocus={() => handleFocus(3)}
                      isFocused={isFocused === 3}
                      disabled={ixStore.selectedEffluent === 3 ? false : true}
                      type='number'
                      name='CO2_Concentration'
                      value={ixStore.selectedEffluent === 3 ? ixStore.effluentValue : 0}
                      onChange={txtChange}
                      id='co'
                      inputText='mg/L'
                    />
                  </div>
                </div>
                <div className='location'>
                  <div className='location-heading'>
                    <CustomHeading
                      fontFamily='NotoSansRegular'
                      fontSize='14px'
                      fontWeight='400'
                      color={colors.PrimaryDarkAquaMarine}
                      label='Location'
                    />
                    <div className='vertical-line'></div>
                  </div>
                  <div className='location-radio-group'>
                    <CustomRadio
                      disabled={ixStore.degasifation_ind ? false : true}
                      type='radio'
                      id='radio4'
                      value={0}
                      name='location'
                      onClick={LocationClick}
                      checked={ixStore.location == 0 ? true : false}
                      label='After WAC, Before SAC'
                    />
                    <CustomRadio
                      disabled={ixStore.degasifation_ind ? false : true}
                      type='radio'
                      id='radio5'
                      value={1}
                      name='location'
                      onClick={LocationClick}
                      checked={defaultAfterCation == true || ixStore.location == 1 ? true : false}
                      label='After cation resin, Before anion resin'
                    />
                    <CustomRadio
                      disabled={ixStore.degasifation_ind ? false : true}
                      type='radio'
                      id='radio6'
                      value={2}
                      name='location'
                      onClick={LocationClick}
                      checked={ixStore.location == 2 ? true : false}
                      label='After WBA, Before SBA'
                    />
                  </div>
                </div>
              </Card.Body>
            </StyledCard>
          )}
        </div>
        {isSumValid && (
          <ProjectErrorPopup
            show={isSumValid}
            close={() => {
              setIsSumValid(false);
              setIsFocused(1);
            }}
            message={message}
          />
        )}
      </VesselRegenerationSystemStyled>
    </>
  );
};

export default VesselRegenerationSystem;
