export const CEB_DETAILS_LIST = [
  {
    key: "bwOxidant",
    prefix: "BW Oxidant",
    isEnabled: "bWDesignTemperature_Ind",
    chemicalId: "oxidantID",
    value: "oxidantDosage",
    valueInPh: "mineralValueInPh_Ind",
    calcField: "flow_BW_oxidant_pump",
    x: "5450",
    y: "2160",
    arrowDuPontCIP: "M5200 2130L5300 2187.74V2072.26L5200 2130ZM5390 2120H5290V2140H5390V2120Z",
    arrowIngeWithCIP: "M5090 2004L5190 2061.74V1946.26L5090 2004ZM5280 1994H5180V2014H5280V1994Z",
    arrowIngeWithoutCIP: "M5090 1610L5190 1667.74V1552.26L5090 1610ZM5280 1600H5180V1620H5280V1600Z",
  },
  {
    key: "cebMineralAcid",
    isEnabled: "mineralEnabled_Ind_CEB",
    chemicalId: "mineralChemId",
    value: "mineralValue",
    valueInPh: "mineralValueInPh_Ind",
    calcField: "flow_CEB1_acid_pump",
    x: "5450",
    y: "2340",
    arrowDuPontCIP: "M5200 2310L5300 2367.74V2252.26L5200 2310ZM5390 2300H5290V2320H5390V2300Z",
    arrowIngeWithCIP: "M5090 2184L5190 2241.74V2126.26L5090 2184ZM5280 2174H5180V2194H5280V2174Z",
    arrowIngeWithoutCIP: "M5090 1790L5190 1847.74V1732.26L5090 1790ZM5280 1780H5180V1800H5280V1780Z",
  },
  {
    key: "cebAlkali",
    isEnabled: "alkaliEnabled_Ind_CEB",
    chemicalId: "alkaliChemId",
    value: "alkaliValue",
    valueInPh: "alkaliValueInPh_Ind",
    calcField: "flow_CEB2_base_pump",
    x: "5450",
    y: "2520",
    arrowDuPontCIP: "M5200 2490L5300 2547.74V2432.26L5200 2490ZM5390 2480H5290V2500H5390V2480Z",
    arrowIngeWithCIP: "M5090 2364L5190 2421.74V2306.26L5090 2364ZM5280 2354H5180V2374H5280V2354Z",
    arrowIngeWithoutCIP: "M5090 1970L5190 2027.74V1912.26L5090 1970ZM5280 1960H5180V1980H5280V1960Z",
  },
  {
    key: "cebOxidant",
    isEnabled: "oxidantEnabled_Ind_CEB",
    chemicalId: "oxidantChemId",
    value: "oxidantValue",
    valueInPh: "oxidantValueInPh_Ind",
    calcField: "flow_CEB2_oxidant_pump",
    x: "5450",
    y: "2710",
    arrowDuPontCIP: "M5200 2680L5300 2737.74V2622.26L5200 2680ZM5390 2670H5290V2690H5390V2670Z",
    arrowIngeWithCIP: "M5090 2554L5190 2611.74V2496.26L5090 2554ZM5280 2544H5180V2564H5280V2544Z",
    arrowIngeWithoutCIP: "M5090 2160L5190 2217.74V2102.26L5090 2160ZM5280 2150H5180V2170H5280V2150Z",
  },
  {
    key: "cebOrganicAcid",
    isEnabled: "organicEnabled_Ind_CEB",
    chemicalId: "organicChemId",
    value: "organicValue",
    valueInPh: "organicValueInPh_Ind",
    calcField: "flow_CEB1_org_acid_pump",
    x: "5450",
    y: "2880",
    arrowDuPontCIP: "M5200 2850L5300 2907.74V2792.26L5200 2850ZM5390 2840H5290V2860H5390V2840Z",
    arrowIngeWithCIP: "M5090 2724L5190 2781.74V2666.26L5090 2724ZM5280 2714H5180V2734H5280V2714Z",
    arrowIngeWithoutCIP: "M5090 2330L5190 2387.74V2272.26L5090 2330ZM5280 2320H5180V2340H5280V2320Z",
  },
];

export const MODULE_DETAIL_FIELDS = [
  { y: "270", text: "Module:" },
  { y: "410", text: "Total UF Units:" },
  { y: "550", text: "UF Modules:" },
  { y: "690", text: "Operating Flux:" },
  { y: "830", text: "UF System Recovery:" },
];

export const DESIGN_INTERVEL_FIELDS = [
  { key: "backwash_design", x: 50, y: 175, label: "Filtration Duration:", xDiff: 1100 },
  { key: "acidCEB", x: 1800, y: 175, label: "Acid CEB:", xDiff: 550 },
  { key: "alkaliOxidantCEB", x: 2875, y: 175, label: "Alkali/Oxidant CEB:", xDiff: 1100 },
  { key: "disinfectionCEB", x: 50, y: 410, label: "Disinfection CEB:", xDiff: 985 },
  { key: "miniCIP", x: 1800, y: 410, label: "mini-CIP:", xDiff: 550 },
  { key: "cIP", x: 2875, y: 410, label: "CIP:", xDiff: 275 },
];

export const DESIGN_INTERVEL_LINES = [
  { x1Diff: 0, x2Diff: 4600, y1Diff: 250, y2Diff: 0 },
  { x1Diff: 1735, x2Diff: 0, y1Diff: 0, y2Diff: 500 },
  { x1Diff: 2800, x2Diff: 0, y1Diff: 0, y2Diff: 500 },
];
