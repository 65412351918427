import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TabList, Tabs } from "react-tabs";

import { SELECTED_UNIT, UNITS } from "@constants/units.constant";

import useUnitConversion from "@hooks/useUnitConversion";
import { useUnitConversionIXD } from "@hooks/useUnitConversionIXD";

import DupontLogger from "@utils/DupontLogger";

import WaveProBrandLogo from "@components/WaveProBrandLogo";

import TabletCloseMenuIcon from "@common/icons/TabletCloseMenuIcon";
import { updateTabAvailable } from "@common/ReportIXDSlice";
import { updateTabAvailableForUF } from "@common/ReportUFSlice";
import { MyError } from "@common/utils/ErrorCreator";
import GlobalUnitConversion from "@common/utils/GlobalUnitConversion";

import { useCreateDataMutation, useLazyGetAllDataQuery } from "@services/apiConfig";

import { updateLoader } from "../../home/CardListSlice";
import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import { RESIN_ID, TECH_IXMB, TRADE_ID } from "./constants/IXDConstants";
import { createProductQualityObject, formatToFourDecimals } from "./IXConfiguration/IXhelper";
import AdvancedRegeneration from "./AdvancedRegeneration";
import Equipment from "./Equipment";
import ExistingPlantDescription from "./ExistingPlantDescription";
import FinalParameterAdjustment from "./FinalParameterAdjustment";
import {
  setIXDUpdate,
  setResinMappingData,
  updateCaseFlag,
  updateDemineralization,
  updateExisting,
  updateIXMenuIconHeader,
  updateIXStore,
  updatelistFinalParamAdj,
  updateResinArrangement,
  updateResinData,
  updateResinDropDownData,
  updateResinName1,
  updateResinName2,
  updateResinName3,
  updateResinName4,
  updateTabletIXMenuIcon,
  updateVesselCylindricalHeight,
} from "./IXDSlice";
import IXDStyled from "./IXDStyled";
import { getDefaultOnVessel1Selected, getDefaultProcessID } from "./IXDUtilityFunction";
import IXInitialization from "./IXInitialization";
import ProductQualityRegenerantDose from "./ProductQualityRegenerantDose";
import RegenerationConditions from "./RegenerationConditions";
import ResinSelection from "./ResinSelection";
import VesselRegenerationSystem from "./VesselRegenerationSystem";

const IXD = ({ techObjID, ixTreatment }) => {
  const dispatch = useDispatch();
  const { convertUnit, convertUnitNumber } = useUnitConversion();
  const ixStoreObj = useSelector(state => state.IXStore);
  const ixStore = useSelector(state => state.IXStore.data);
  const ixTreatmentInStore = useSelector(state => state.IXStore.data.treatmentName);
  const { vessel1, vessel2, vessel3, vessel4, effluentValue } = ixStore;
  const ProjectInfoStore = useSelector(state => state.projectInfo.data);
  const [ixLefNametpanel, setIxLefNametpanel] = useState("Vessel & Regeneration System");
  const [errorInPanel, setErrorInPanel] = useState("Vessel & Regeneration System");

  const UserInfoStore = useSelector(state => state.userInfo.data);
  const userID = UserInfoStore.UserId;
  const [getIXDetails, responseIXDetails] = useLazyGetAllDataQuery();
  const [getIXResin, responseIXResin] = useLazyGetAllDataQuery();
  const [getIXVessel, responseIXVessel] = useLazyGetAllDataQuery();
  const [getIXResinMappingData, responseIXResinMappingData] = useLazyGetAllDataQuery();

  const caseID = ProjectInfoStore.caseId;

  const ixStore1 = useSelector(state => state.IXStore.data.selectedResinList.filter(item => item.columnNo === 1));
  const ixStore2 = useSelector(state => state.IXStore.data.selectedResinList.filter(item => item.columnNo === 2));
  const { ixdActiveTab } = useSelector(state => state.IXStore);
  const [selectedProcessID, setSelectedProcessId] = useState(null);
  const caseTreatmentID = useSelector(
    state =>
      state.processDiagramSlice.lstTechnologyLists.find(item => item?.technologyID == techObjID && !item?.isDeleted)
        ?.caseTreatmentID,
  );
  const caseTreatmentID2 = useSelector(state => state.processDiagramSlice.lstTechnologyLists);
  const [getDemineralization, responseDemineralization] = useLazyGetAllDataQuery();
  const [selectedPanel, setSelectedPanel] = useState(ixdActiveTab);
  const [isError, setIsError] = useState(false);
  const [defautVessel, setDefaultVtessel] = useState(false);
  const cationAdvRegen = useSelector(state => state.IXStore?.updateCationDataAdvRegen);
  const anionAdvRegen = useSelector(state => state.IXStore?.updateAnionDataAdvRegen);
  const ixResinID1 = ixStore?.selectedResinList[0]?.ixResinID1;
  const ixResinID2 = ixStore?.selectedResinList[0]?.ixResinID2;
  const ixResinID3 = ixStore?.selectedResinList[1]?.ixResinID1;
  const ixResinID4 = ixStore?.selectedResinList[1]?.ixResinID2;
  const { unitConversionByName } = useUnitConversion();
  const Logger = DupontLogger("IXD");
  const { updateIXConversion } = useUnitConversionIXD();

  const GlobalUnitConversionStore = useSelector(state => state.GUnitConversion.data);
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const resinVal = useSelector(state => state.IXStore.data?.listRegenConds);
  const caseFlag = useSelector(state => state.IXStore?.caseFlag);
  const ixStoreAdvance = useSelector(state => state.IXStore?.data?.listAdvRegen);
  const ixRegenreteDose = useSelector(state => state.IXStore?.data?.listProductQualityandregeneration);
  const jsonResinData = useSelector(state => state.IXStore.jsonResinData);
  const resinStore = useSelector(state => state.IXStore.resinData);
  let wac = resinStore.WAC;
  let wba = resinStore.WBA;
  let sac = resinStore.SAC;
  let sba = resinStore.SBA;
  const [resinItem1, resinItem2] = ixStore.selectedResinList;

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!ixTreatmentInStore) {
      dispatch(updateIXStore({ data: { treatmentName: ixTreatment } }));
    }
  }, [ixTreatmentInStore]);

  useEffect(() => {
    updateIXConversion();
  }, [unit.selectedUnits]);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsError(false);
      setIsLocationError(false);
    }
  };
  useEffect(() => {
    if (vessel1 === 0 || vessel2 === 0 || vessel3 === 0 || vessel4 === 0) {
      setDefaultVtessel(true);
    } else {
      setDefaultVtessel(false);
    }
  }, [vessel1, vessel2, vessel3, vessel4]);
  const [isLocationError, setIsLocationError] = useState(false);

  const objProductQuality = [
    createProductQualityObject(
      {
        averageSpeciesVal: defautVessel ? 100 : 20,
        endpoingSpeciesVal: defautVessel ? 200 : 40,
        averageConductivityVal: defautVessel ? 1.07 : 0.22,
        endpointConductivityVal: defautVessel ? 1.07 : 0.22,
      },
      cationAdvRegen,
    ),
    createProductQualityObject(
      {
        averageSpeciesVal: defautVessel ? 100 : 20,
        endpoingSpeciesVal: defautVessel ? 200 : 40,
        averageConductivityVal: 0,
        endpointConductivityVal: 70,
      },
      anionAdvRegen,
    ),
  ];
  const baseMenu = [
    {
      Id: 1,
      Name: "IXInitialization",
      Value: "IX Initialization",
    },
    {
      Id: 2,
      Name: "VesselRegeneration",
      Value: "Vessel & Regeneration System",
    },
    {
      Id: 3,
      Name: "ResinSelection",
      Value: "Resin Selection",
    },
    {
      Id: 4,
      Name: "RegenerationConditions",
      Value: "Regeneration Conditions",
    },
    {
      Id: 5,
      Name: "AdvancedRegeneration",
      Value: "Advanced Regeneration",
    },
    {
      Id: 6,
      Name: "Equipment",
      Value: "Equipment",
    },
    {
      Id: 7,
      Name: "ProductQuality",
      Value: "Product Quality & Regenerant Dose",
    },
  ];

  function updateBaseMenuForVessel1(baseMenu, vessel1Selected) {
    if (vessel1Selected) {
      const itemIndex = baseMenu.findIndex(item => item.Id === 6);
      if (itemIndex !== -1) {
        baseMenu[itemIndex] = {
          ...baseMenu[itemIndex],
          Value: vessel1 === 9 ? "External Equipment" : "Equipment",
        };
      }
    }
    return baseMenu;
  }

  const IXMBMenu = ixStore.evaluteExisting_ind
    ? [
        ...updateBaseMenuForVessel1(baseMenu, ixStore.vessel1),
        { Id: 8, Name: "ExistingPlant", Value: "Existing Plant Description" },
        { Id: 9, Name: "FinalParameterAdjustments", Value: "Final Parameter Adjustments" },
      ]
    : updateBaseMenuForVessel1(baseMenu, ixStore.vessel1);

  const IxdLeftMenuFinal = ixStore.evaluteExisting_ind
    ? [...baseMenu, { Id: 8, Name: "ExistingPlant", Value: "Existing Plant Description" }]
    : [...baseMenu, { Id: 9, Name: "FinalParameterAdjustments", Value: "Final Parameter Adjustments" }];

  const IxdLeftMenu =
    ixTreatment === TECH_IXMB
      ? IXMBMenu
      : ixStore.evaluteExisting_ind
        ? [...baseMenu, { Id: 8, Name: "ExistingPlant", Value: "Existing Plant Description" }]
        : baseMenu;

  const [IXData_PostData] = useCreateDataMutation();
  const SaveUpdatedData = async () => {
    const existingData = {
      ...ixStore,
      existingPlantDescription: ixStoreObj.existingPlantDescription,
    };
    dispatch(updateIXStore(existingData));
    let dummyListFinal = ixStoreObj?.listFinalParamAdj;
    if (dummyListFinal.length <= 1) {
      let vesselCount = 0;
      if (ixStoreObj.resinName4 !== null) {
        vesselCount = 4;
      } else if (ixStoreObj.resinName3 !== null) {
        vesselCount = 3;
      } else if (ixStoreObj.resinName2 !== null) {
        vesselCount = 2;
      } else {
        vesselCount = 1;
      }
      const dummyArray = Array.from({ length: vesselCount }, (_, index) => ({
        resinType: ixStoreObj.resinData[ixStoreObj[`resinName${index + 1}`]],
        resinName: ixStoreObj[`resinName${index + 1}`],
        resinId:
          ixStoreObj[`resinName${index + 1}`] == "WAC"
            ? ixResinID1
            : ixStoreObj[`resinName${index + 1}`] == "SAC"
              ? ixResinID2
              : ixStoreObj[`resinName${index + 1}`] == "WBA"
                ? ixResinID3
                : ixResinID4,
        vesselNo: index + 1,
        resinVolumeAsDelivered: 0,
        vesselDiameter: 0,
        resinBedHeightAsDelivered: 0,
        resinBedStandardHeight: 0,
        resinBedHeightAsExhausted: 0,
        resinBedHeightAsRegenerated: 0,
        inertResinVolume: 0,
        inertBedHeight: 0,
        freeBoard: 0,
        vesselCylindricalHeight: 0,
        vesselWallThickness: 0,
        pressureDropwithRecomQty: 0,
        resinPackagingSize: 0,
        ixfpaRadioButtonID: 0,
      }));
      if (vesselCount > 1) {
        dummyListFinal = dummyArray;
      }
    }
    const list = [...dummyListFinal];
    dummyListFinal = list.map((item, index) => {
      const resinVolumeAsDelivered = convertUnitNumber(
        item.resinVolumeAsDelivered,
        UNITS.CUBIC_METER,
        SELECTED_UNIT.VOLUME,
        0,
      );
      const inertResinVolume = convertUnitNumber(item.inertResinVolume, UNITS.CUBIC_METER, SELECTED_UNIT.VOLUME, 0);
      const vesselDiameter = convertUnitNumber(item.vesselDiameter, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
      const resinBedHeightAsDelivered = convertUnitNumber(
        item.resinBedHeightAsDelivered,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const resinBedStandardHeight = convertUnitNumber(
        item.resinBedStandardHeight,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const resinBedHeightAsRegenerated = convertUnitNumber(
        item.resinBedHeightAsRegenerated,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const resinBedHeightAsExhausted = convertUnitNumber(
        item.resinBedHeightAsExhausted,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const inertBedHeight = convertUnitNumber(item.inertBedHeight, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
      const vesselCylindricalHeight = convertUnitNumber(
        item.vesselCylindricalHeight,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const vesselWallThickness = convertUnitNumber(
        item.vesselWallThickness,
        UNITS.millimeter,
        SELECTED_UNIT.LENGTH,
        0,
      );
      const freeBoard = convertUnitNumber(item.freeBoard, UNITS.millimeter, SELECTED_UNIT.LENGTH, 0);
      return {
        ...item,
        resinVolumeAsDelivered: parseFloat(resinVolumeAsDelivered),
        inertResinVolume: parseFloat(inertResinVolume),
        vesselDiameter: parseFloat(vesselDiameter),
        resinBedHeightAsDelivered: parseFloat(resinBedHeightAsDelivered),
        resinBedStandardHeight: parseFloat(resinBedStandardHeight),
        resinBedHeightAsRegenerated: parseFloat(resinBedHeightAsRegenerated),
        resinBedHeightAsExhausted: parseFloat(resinBedHeightAsExhausted),
        inertBedHeight: parseFloat(inertBedHeight),
        vesselCylindricalHeight: parseFloat(vesselCylindricalHeight),
        vesselWallThickness: parseFloat(vesselWallThickness),
        freeBoard: parseFloat(freeBoard),
      };
    });

    /*----Unit conversion for regenenConditionPage start-----*/
    const [a, b] = resinVal;
    let cationTemp = resinVal[0]?.temperature;
    let anionTemp = resinVal[1]?.temperature;
    if (a) {
      cationTemp = Number(
        GlobalUnitConversion(GlobalUnitConversionStore, resinVal[0]?.temperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      );
    }
    if (b) {
      anionTemp = Number(
        GlobalUnitConversion(GlobalUnitConversionStore, resinVal[1]?.temperature, "°C", unit.selectedUnits[2]).toFixed(
          2,
        ),
      );
    }
    const [Ra, Rd] = ixRegenreteDose;
    let cationRegenreteDoseVel = ixRegenreteDose[0]?.regenerantDoseVal4;
    let anionRegenreteDoseVel = ixRegenreteDose[1]?.regenerantDoseVal4;
    let cationAverageConduc = ixRegenreteDose[0]?.averageConductivityVal;
    let anionAverageConduc = ixRegenreteDose[1]?.averageConductivityVal;
    let cationendpointConduc = ixRegenreteDose[0]?.endpointConductivityVal;
    let anionendpointConduc = ixRegenreteDose[1]?.endpointConductivityVal;
    if (Ra) {
      cationRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      cationAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      cationendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[0]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    if (Rd) {
      anionRegenreteDoseVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.regenerantDoseVal4,
        "g/L",
        unit.selectedUnits[14],
      );
      anionAverageConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.averageConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
      anionendpointConduc = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixRegenreteDose[1]?.endpointConductivityVal,
        "µS/cm",
        unit.selectedUnits[17],
      );
    }
    /*----Unit conversion for regenenConditionPage end-----*/
    /*----Unit conversion for Advance Regeneration start-----*/
    const [c, d] = ixStoreAdvance;
    let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
    let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
    let cationDisVol = ixStoreAdvance[0]?.displacementVolume;
    let anionDisVol = ixStoreAdvance[1]?.displacementVolume;
    let cationFasVol = ixStoreAdvance[0]?.fatRinseVolume;
    let anionFasVol = ixStoreAdvance[1]?.fatRinseVolume;
    if (c) {
      cationregenVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      cationDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      cationFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[0]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }
    if (d) {
      anionregeneVel = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.regenerationVelocity,
        "BV/h",
        unit.selectedUnits[10],
      );
      anionDisVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.displacementVolume,
        "BV",
        unit.selectedUnits[13],
      );
      anionFasVol = GlobalUnitConversion(
        GlobalUnitConversionStore,
        ixStoreAdvance[1]?.fatRinseVolume,
        "BV",
        unit.selectedUnits[13],
      );
    }

    const MethodName = { Method: "ix/api/v1/AutoSaveIXData" };
    const IXData_Method_Body = {
      ...MethodName,
      ...ixStore,
      pdExtPiping: unitConversionByName(ixStore.pdExtPiping, UNITS.bar, unit.selectedUnits[3]),
      pdIntDistributor: unitConversionByName(ixStore.pdIntDistributor, UNITS.bar, unit.selectedUnits[3]),
      effluentPressure: unitConversionByName(ixStore.effluentPressure, UNITS.bar, unit.selectedUnits[3]),
      tankTemperature: unitConversionByName(ixStore.tankTemperature, UNITS.celsius, unit.selectedUnits[2]),
      backwashTowerDiameter: unitConversionByName(
        ixStore.backwashTowerDiameter || getDefaultOnVessel1Selected(ixTreatment, ixStore?.vessel1),
        UNITS.millimeter,
        unit.selectedUnits[8],
      ),
      sacRegenVesselDia: unitConversionByName(
        ixStore.sacRegenVesselDia || getDefaultOnVessel1Selected(ixTreatment, ixStore?.vessel1),
        UNITS.millimeter,
        unit.selectedUnits[8],
      ),
      sbaRegenVesselDia: unitConversionByName(
        ixStore.sbaRegenVesselDia || getDefaultOnVessel1Selected(ixTreatment, ixStore?.vessel1),
        UNITS.millimeter,
        unit.selectedUnits[8],
      ),
      treatmentObjID: caseTreatmentID,
      caseTreatmentID: caseTreatmentID,
      ["space_velocity_txt"]: Number(
        GlobalUnitConversion(
          GlobalUnitConversionStore,
          ixStore.space_velocity_txt,
          "BV/h",
          unit.selectedUnits[10],
        ).toFixed(2),
      ),
      listRegenConds: [
        { ...ixStore.listRegenConds[0], ["temperature"]: cationTemp },
        { ...ixStore.listRegenConds[1], ["temperature"]: anionTemp },
      ],
      selectedResinList: [
        { ...resinItem1, ["inert"]: resinItem1?.inert || 0 },
        { ...resinItem2, ["inert"]: resinItem2?.inert || 0 },
      ],
      listAdvRegen: [
        {
          ...ixStore.listAdvRegen[0],
          ["regenerationVelocity"]: Number(cationregenVel?.toFixed(2)),
          ["displacementVolume"]: Number(cationDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(cationFasVol?.toFixed(2)),
        },
        {
          ...ixStore.listAdvRegen[1],
          ["regenerationVelocity"]: Number(anionregeneVel?.toFixed(2)),
          ["displacementVolume"]: Number(anionDisVol?.toFixed(2)),
          ["fatRinseVolume"]: Number(anionFasVol?.toFixed(2)),
        },
      ],
      listProductQualityandregeneration: [
        {
          ...ixStore.listProductQualityandregeneration[0],
          ["regenerantDoseVal4"]: cationRegenreteDoseVel,
          ["averageConductivityVal"]: cationAverageConduc,
          ["endpointConductivityVal"]: cationendpointConduc,
        },
        {
          ...ixStore.listProductQualityandregeneration[1],
          ["regenerantDoseVal4"]: anionRegenreteDoseVel,
          ["averageConductivityVal"]: anionAverageConduc,
          ["endpointConductivityVal"]: anionendpointConduc,
        },
      ],
      listFinalParamAdj: dummyListFinal,
    };
    const PostResponseValues = await IXData_PostData(IXData_Method_Body);
    dispatch(setIXDUpdate(false));
  };
  useEffect(() => {
    try {
      if (ProjectInfoStore.projectID != 0 && userID != 0) {
        const Url = `${"ix/api/v1/IXDetails"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&caseID=${caseID}&treatmentObjID=${caseFlag ? caseTreatmentID2[caseTreatmentID2.length - 1]?.caseTreatmentID : caseTreatmentID}`;
        getIXDetails(Url);
        dispatch(updateCaseFlag(false));
      }
    } catch {
      console.log("Error: Fetch IXDetails data base on ixLeftpanel selectin");
    }
  }, [ProjectInfoStore.projectID, userID]);

  useEffect(() => {
    let apiUrl = `${"ix/api/v1/IXResin"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&validdesignID=${ixStore.validDesignID}&processID=${selectedProcessID}`;
    if (ixStore.vessel1 !== null) {
      apiUrl += `&vessel1=${ixStore.vessel1}`;
    }
    if (ixStore.vessel2 !== null) {
      apiUrl += `&vessel2=${ixStore.vessel2}`;
    }
    if (ixStore.vessel3 !== null) {
      apiUrl += `&vessel3=${ixStore.vessel3}`;
    }
    if (ixStore.vessel4 !== null) {
      apiUrl += `&vessel4=${ixStore.vessel4}`;
    }
    if (TRADE_ID !== null) {
      apiUrl += `&tradeID=${TRADE_ID}`;
    }

    const resinArrangementUrl = `${"ix/api/v1/VesselRegenerationSystem"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixTreatment=${ixTreatment}&selectedprocessid=${selectedProcessID}`;
    if (selectedProcessID) {
      getIXResin(apiUrl);
      getIXVessel(resinArrangementUrl);
    }
  }, [selectedProcessID]);

  useEffect(() => {
    if (responseIXDetails.isSuccess === true) {
      //   setResponseSuccessFlag(true);
      //  dispatch(updateIXDetailsFetched(true));
      let data1 = null;
      let data2 = null;
      let data3 = null;
      let data4 = null;
      if (responseIXDetails?.data?.cationResin === 3) {
        data1 = "WAC";
        data2 = null;
      } else if (responseIXDetails?.data?.cationResin === 2) {
        data1 = "SAC";
        data2 = null;
      } else if (
        responseIXDetails?.data?.cationResin === 5 ||
        responseIXDetails?.data?.cationResin === 6 ||
        responseIXDetails?.data?.cationResin === 7
      ) {
        data1 = "WAC";
        data2 = "SAC";
      }
      if (responseIXDetails?.data?.anionResin === 4) {
        data3 = "WBA";
        data4 = null;
      } else if (responseIXDetails?.data?.anionResin === 1) {
        data3 = "SBA";
        data4 = null;
      } else if (
        responseIXDetails?.data?.anionResin === 11 ||
        responseIXDetails?.data?.anionResin === 12 ||
        responseIXDetails?.data?.anionResin === 13
      ) {
        data3 = "WBA";
        data4 = "SBA";
      }
      if (
        responseIXDetails?.data?.anionResin === responseIXDetails?.data?.cationResin &&
        responseIXDetails?.data?.anionResin !== null
      ) {
        data1 = "SAC";
        data2 = "SBA";
      }
      if (data1 !== null && data2 === null) {
        data2 = data3;
        data3 = data4;
        data4 = null;
      }
      dispatch(updateResinName1(data1));
      dispatch(updateResinName2(data2));
      dispatch(updateResinName3(data3));
      dispatch(updateResinName4(data4));

      const fetchedList = responseIXDetails?.data?.listFinalParamAdj;
      const layout = [];
      const vessel = [];
      let vesselNo = 1;
      const { cationResin, anionResin } = responseIXDetails?.data || {};
      const pushLayoutAndVessel = (iscation, vesselNo) => {
        layout.push(iscation ? "C" : "A");
        vessel.push(vesselNo);
      };
      if ([RESIN_ID.WAC_RESIN_ID, RESIN_ID.SAC_RESIN_ID].includes(cationResin)) {
        pushLayoutAndVessel(true, vesselNo);
        vesselNo++;
      }

      if (cationResin == RESIN_ID.WAC_SAC_TWO_VESSEL_RESIN_ID) {
        pushLayoutAndVessel(true, vesselNo);
        vesselNo++;
        pushLayoutAndVessel(true, vesselNo);
        vesselNo++;
      }

      if ([RESIN_ID.WAC_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WAC_SAC_TWO_CHAMBER_RESIN_ID].includes(cationResin)) {
        pushLayoutAndVessel(true, vesselNo);
        pushLayoutAndVessel(true, vesselNo);
        vesselNo++;
      }

      if ([RESIN_ID.WBA_RESIN_ID, RESIN_ID.SBA_RESIN_ID].includes(anionResin)) {
        pushLayoutAndVessel(false, vesselNo);
        vesselNo++;
      }

      if (anionResin == RESIN_ID.WBA_SBA_TWO_VESSEL_RESIN_ID) {
        pushLayoutAndVessel(false, vesselNo);
        vesselNo++;
        pushLayoutAndVessel(false, vesselNo);
        vesselNo++;
      }

      if ([RESIN_ID.WBA_SBA_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.WBA_SBA_TWO_CHAMBER_RESIN_ID].includes(anionResin)) {
        pushLayoutAndVessel(false, vesselNo);
        pushLayoutAndVessel(false, vesselNo);
        vesselNo++;
      }
      if ([RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID, RESIN_ID.SBA_SAC_SINGLE_CHAMBER_RESIN_ID].includes(anionResin)) {
        pushLayoutAndVessel(true, vesselNo);
        pushLayoutAndVessel(true, vesselNo);
        vesselNo++;
      }
      const finalList = fetchedList?.map((obj, index) => {
        // Format numerical values to four decimal places
        const formattedObj = {};
        for (const key in obj) {
          formattedObj[key] = formatToFourDecimals(obj[key]);
        }
        dispatch(
          updateVesselCylindricalHeight({
            isInput: false,
            value: formattedObj.vesselCylindricalHeight,
            freeBoard: formattedObj.freeBoard,
            index: index,
            vesselName: `Vessel${vessel[index]}`,
          }),
        );
        return {
          ...formattedObj,
          resinName: eval(`data${index + 1}`),
          resinType: obj.resinType,
          resinId: obj.resinId,
          ixfpaRadioButtonID: obj.ixfpaRadioButtonID,
          isCation: layout[index] == "C",
          vesselNo: vessel[index],
        };
      });
      dispatch(updateExisting(finalList));
      dispatch(updatelistFinalParamAdj(finalList));
      const obj = { ...ixStore };
      const newObj = responseIXDetails?.data;
      const keys = Object.keys(newObj);
      keys.map(x => {
        obj[x] = newObj[x];
      });
      obj.userID = userID;
      obj.projectID = ProjectInfoStore.projectID;
      obj.caseID = caseID;
      obj.treatmentObjID = caseTreatmentID;
      obj.caseTreatmentID = caseTreatmentID;
      obj.selectedProcessID = responseIXDetails?.data?.selectedProcessID || getDefaultProcessID(ixTreatment);
      obj.treatmentName = responseIXDetails?.data?.treatmentName;

      setSelectedProcessId(responseIXDetails?.data?.selectedProcessID || getDefaultProcessID(ixTreatment));

      if (unit.selectedUnits[10] !== "BV/h") {
        /*----Unit conversion for Advance Regeneration start-----*/
        const [c, d] = ixStoreAdvance;
        let cationregenVel = ixStoreAdvance[0]?.regenerationVelocity;
        let anionregeneVel = ixStoreAdvance[1]?.regenerationVelocity;
        if (c) {
          cationregenVel = GlobalUnitConversion(
            GlobalUnitConversionStore,
            responseIXDetails.data?.listAdvRegen[0]?.regenerationVelocity,
            unit.selectedUnits[10],
            "BV/h",
          );
        }
        if (d) {
          anionregeneVel = GlobalUnitConversion(
            GlobalUnitConversionStore,
            responseIXDetails.data?.listAdvRegen[1]?.regenerationVelocity,
            unit.selectedUnits[10],
            "BV/h",
          );
        }
        /*----Unit conversion for Advance Regeneration end-----*/

        obj.space_velocity_txt = GlobalUnitConversion(
          GlobalUnitConversionStore,
          responseIXDetails.data.space_velocity_txt,
          unit.selectedUnits[10],
          "BV/h",
        );

        obj.listAdvRegen = [
          {
            ...obj.listAdvRegen[0],
            ["regenerationVelocity"]: Number(cationregenVel?.toFixed(2)),
          },
          {
            ...obj.listAdvRegen[1],
            ["regenerationVelocity"]: Number(anionregeneVel?.toFixed(2)),
          },
        ];
      }
      if (unit.selectedUnits[2] !== "°C") {
        const [a, b] = responseIXDetails.data.listRegenConds;
        let cationTemp = responseIXDetails.data?.listRegenConds[0]?.temperature;
        let anionTemp = responseIXDetails.data?.listRegenConds[1]?.temperature;
        if (a) {
          cationTemp = GlobalUnitConversion(
            GlobalUnitConversionStore,
            responseIXDetails?.data?.listRegenConds[0]?.temperature,
            unit.selectedUnits[2],
            "°C",
          );
        }
        if (b) {
          anionTemp = GlobalUnitConversion(
            GlobalUnitConversionStore,
            responseIXDetails?.data?.listRegenConds[1]?.temperature,
            unit.selectedUnits[2],
            "°C",
          );
        }
        obj.listRegenConds = [
          { ...obj.listRegenConds[0], ["temperature"]: cationTemp },
          { ...obj.listRegenConds[1], ["temperature"]: anionTemp },
        ];

        obj.tankTemperature = unitConversionByName(
          responseIXDetails?.data?.tankTemperature,
          unit.selectedUnits[2],
          UNITS.celsius,
        );
      }
      if (unit.selectedUnits[12] !== UNITS.CUBIC_METER) {
        const list = [...obj.listFinalParamAdj];
        const newList = list.map((item, index) => {
          const finalParams = obj.listFinalParamAdj[index];

          const resinVolumeAsDelivered = convertUnit(
            finalParams.resinVolumeAsDelivered,
            SELECTED_UNIT.VOLUME,
            UNITS.CUBIC_METER,
            0,
          );
          const inertResinVolume = convertUnit(
            finalParams.inertResinVolume,
            SELECTED_UNIT.VOLUME,
            UNITS.CUBIC_METER,
            0,
          );
          return {
            ...item,
            resinVolumeAsDelivered: formatToFourDecimals(resinVolumeAsDelivered),
            inertResinVolume: formatToFourDecimals(inertResinVolume),
          };
        });
        obj.listFinalParamAdj = newList;
        dispatch(updateExisting(newList));
        dispatch(updatelistFinalParamAdj(newList));
      }
      if (unit.selectedUnits[8] !== UNITS.millimeter) {
        const list = [...obj.listFinalParamAdj];
        const newList = list.map((item, index) => {
          const finalParams = obj.listFinalParamAdj[index];
          const vesselDiameter = convertUnit(finalParams.vesselDiameter, SELECTED_UNIT.LENGTH, UNITS.millimeter, 0);
          const resinBedHeightAsDelivered = convertUnit(
            finalParams.resinBedHeightAsDelivered,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          const resinBedStandardHeight = convertUnit(
            finalParams.resinBedStandardHeight,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          const resinBedHeightAsRegenerated = convertUnit(
            finalParams.resinBedHeightAsRegenerated,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          const resinBedHeightAsExhausted = convertUnit(
            finalParams.resinBedHeightAsExhausted,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          const freeBoard = convertUnit(finalParams.freeBoard, SELECTED_UNIT.LENGTH, UNITS.millimeter, 0, false);
          const inertBedHeight = convertUnit(finalParams.inertBedHeight, SELECTED_UNIT.LENGTH, UNITS.millimeter, 0);
          const vesselCylindricalHeight = convertUnit(
            finalParams.vesselCylindricalHeight,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          const vesselWallThickness = convertUnit(
            finalParams.vesselWallThickness,
            SELECTED_UNIT.LENGTH,
            UNITS.millimeter,
            0,
          );
          dispatch(
            updateVesselCylindricalHeight({
              isInput: false,
              value: formatToFourDecimals(vesselCylindricalHeight),
              freeBoard: formatToFourDecimals(freeBoard),
              index: index,
            }),
          );
          return {
            ...item,
            vesselDiameter: formatToFourDecimals(vesselDiameter),
            resinBedHeightAsDelivered: formatToFourDecimals(resinBedHeightAsDelivered),
            resinBedStandardHeight: formatToFourDecimals(resinBedStandardHeight),
            resinBedHeightAsRegenerated: formatToFourDecimals(resinBedHeightAsRegenerated),
            resinBedHeightAsExhausted: formatToFourDecimals(resinBedHeightAsExhausted),
            inertBedHeight: formatToFourDecimals(inertBedHeight),
            vesselCylindricalHeight: formatToFourDecimals(vesselCylindricalHeight),
            vesselWallThickness: formatToFourDecimals(vesselWallThickness),
            freeBoard: formatToFourDecimals(freeBoard),
          };
        });
        obj.listFinalParamAdj = newList;
        dispatch(updateExisting(newList));
        dispatch(updatelistFinalParamAdj(newList));
        obj.pdExtPiping = unitConversionByName(
          responseIXDetails?.data?.pdExtPiping || 0.1,
          unit.selectedUnits[3],
          UNITS.bar,
        );
        obj.pdIntDistributor = unitConversionByName(
          responseIXDetails?.data?.pdIntDistributor || 0.05,
          unit.selectedUnits[3],
          UNITS.bar,
        );
        obj.effluentPressure = unitConversionByName(
          responseIXDetails?.data?.effluentPressure || 1,
          unit.selectedUnits[3],
          UNITS.bar,
        );
        obj.backwashTowerDiameter = unitConversionByName(
          responseIXDetails?.data?.backwashTowerDiameter ||
            getDefaultOnVessel1Selected(ixTreatment, responseIXDetails?.data?.vessel1),
          unit.selectedUnits[8],
          UNITS.millimeter,
        );

        obj.sacRegenVesselDia = unitConversionByName(
          responseIXDetails?.data?.sacRegenVesselDia ||
            getDefaultOnVessel1Selected(ixTreatment, responseIXDetails?.data?.vessel1),
          unit.selectedUnits[8],
          UNITS.millimeter,
        );
        obj.sbaRegenVesselDia = unitConversionByName(
          responseIXDetails?.data?.sbaRegenVesselDia ||
            getDefaultOnVessel1Selected(ixTreatment, responseIXDetails?.data?.vessel1),
          unit.selectedUnits[8],
          UNITS.millimeter,
        );
      }
      if (unit.selectedUnits[17] !== "µS/cm") {
        obj.averageConductivityVal = GlobalUnitConversion(
          GlobalUnitConversionStore,
          responseIXDetails?.data?.averageConductivityVal,
          unit.selectedUnits[17],
          "µS/cm",
        );
        obj.endpointConductivityVal = GlobalUnitConversion(
          GlobalUnitConversionStore,
          responseIXDetails?.data?.endpointConductivityVal,
          unit.selectedUnits[17],
          "µS/cm",
        );
      }

      obj.ixTreatment = ixTreatment;
      if (obj.listProductQualityandregeneration.length <= 0) {
        obj.listProductQualityandregeneration = objProductQuality;
        if (unit.selectedUnits[14] !== "g/L") {
          const list = [...objProductQuality];
          const newList = list.map((item, index) => {
            const regenerantDoseVal4 = GlobalUnitConversion(
              GlobalUnitConversionStore,
              list[index].regenerantDoseVal4,
              unit.selectedUnits[14],
              "g/L",
            );
            return { ...item, ["regenerantDoseVal4"]: Number.parseFloat(regenerantDoseVal4).toFixed(2) };
          });
          obj.listProductQualityandregeneration = newList;
        }
      }
      if (unit.selectedUnits[14] !== "g/L") {
        const list = [...responseIXDetails.data.listProductQualityandregeneration];
        const newList = list.map((item, index) => {
          const regenerantDoseVal4 = GlobalUnitConversion(
            GlobalUnitConversionStore,
            list[index].regenerantDoseVal4,
            unit.selectedUnits[14],
            "g/L",
          );
          return { ...item, ["regenerantDoseVal4"]: Number.parseFloat(regenerantDoseVal4).toFixed(2) };
        });
        obj.listProductQualityandregeneration = newList;
      }

      dispatch(updateIXStore(obj));
      dispatch(setIXDUpdate(false));
    } else if (responseIXDetails.isError) {
      throw new MyError("IXDetails Api Error", responseIXDetails.error.status, "ApiError");
    }
  }, [responseIXDetails]);
  useEffect(() => {
    if (responseIXResin.isSuccess === true) {
      //   setResponseSuccessFlag(false);
      dispatch(updateResinDropDownData(responseIXResin.data.responseResinSelections));
    } else if (responseIXResin.isError) {
      throw new MyError("IXResin Api Error", responseIXResin.error.status, "ApiError");
    }
  }, [responseIXResin]);

  useEffect(() => {
    if (responseIXVessel.isSuccess) {
      // setResponseSuccessFlag(false);
      // Combine all arrays into one
      const combinedArray = Object.values(responseIXVessel.data).reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        [],
      );
      const filteredArray = combinedArray.filter(obj => obj.ixResinArrangmentID !== 0);
      dispatch(updateResinArrangement(filteredArray));
    } else if (responseIXVessel.isError) {
      throw new MyError("IXResin Api Error", responseIXVessel.error.status, "ApiError");
    }
  }, [responseIXVessel]);
  useEffect(() => {
    wac = jsonResinData
      ?.find(item => item.resinName === "WAC")
      ?.listIXResins.find(item => item.ResinId === ixStore1[0]?.ixResinID1);
    sac = jsonResinData
      ?.find(item => item.resinName === "SAC")
      ?.listIXResins.find(item => item.ResinId === ixStore1[0]?.ixResinID2);
    wba = jsonResinData
      ?.find(item => item.resinName === "WBA")
      ?.listIXResins.find(item => item.ResinId === ixStore2[0]?.ixResinID1);
    sba = jsonResinData
      ?.find(item => item.resinName === "SBA")
      ?.listIXResins.find(item => item.ResinId === ixStore2[0]?.ixResinID2);

    dispatch(
      updateResinData({
        ...resinStore,
        ["WAC"]: wac ? wac.ResinName : null,
        ["SAC"]: sac ? sac.ResinName : null,
        ["WBA"]: wba ? wba.ResinName : null,
        ["SBA"]: sba ? sba.ResinName : null,
        ["resinId1"]: wac ? wac.ResinId : null,
        ["resinId2"]: sac ? sac.ResinId : null,
        ["resinId3"]: wba ? wba.ResinId : null,
        ["resinId4"]: sba ? sba.ResinId : null,
      }),
    );
  }, [jsonResinData]);
  useEffect(() => {
    const apiUrlResinMapping = "ix/api/v1/ResinMapping";
    try {
      getDemineralization(
        `${"ix/api/v1/IXInitializationData"}?userID=${userID}&projectID=${ProjectInfoStore.projectID}&ixTreatment=${ixTreatment}`,
      );
      getIXResinMappingData(apiUrlResinMapping);
    } catch {
      console.log("Error: Fetch IXInitializationData data");
    }
  }, []);

  useEffect(() => {
    if (responseIXResinMappingData.isSuccess) {
      dispatch(setResinMappingData(responseIXResinMappingData.data));
    }
  }, [responseIXResinMappingData]);

  useEffect(() => {
    if (responseDemineralization.isLoading) {
      dispatch(updateLoader(true));
    } else if (responseDemineralization.isSuccess === true) {
      dispatch(updateLoader(false));
      dispatch(updateDemineralization(responseDemineralization.data));
    } else if (responseDemineralization.isError) {
      throw new MyError("Demineralization Api Error", responseDemineralization.error.status, "ApiError");
    }
  }, [responseDemineralization]);
  const updateIXLeftpanel = data => {
    const { vesselFlags, selectedcationResign, selectedanionResign, data: ixData } = ixStoreObj;
    const { cationResin, anionResin, listRegenConds, degasifation_ind, location } = ixStore;

    const dumyVessel = ["vesselflag1", "vesselflag2", "vesselflag3", "vesselflag4"].map(
      (flag, i) => vesselFlags[flag] || ixStore[`vessel${i + 1}`] !== null,
    );

    const commonValidator = cationResin && anionResin && dumyVessel.every(Boolean);
    const effluentValidator = degasifation_ind ? effluentValue !== 0 : true;
    let validator = commonValidator && effluentValidator;

    const specialCondition =
      selectedcationResign === "[WAC]" &&
      selectedanionResign === "[WBA] - [SBA]" &&
      degasifation_ind &&
      location === null;

    if (specialCondition) {
      validator = false;
    }

    const advancedValidator =
      validator && listRegenConds.length === 2 && listRegenConds.every(cond => cond?.regenerantID);
    const canMove = {
      IXInitialization: true,
      VesselRegeneration: commonValidator,
      ResinSelection: validator,
      RegenerationConditions: advancedValidator && validator,
      AdvancedRegeneration: advancedValidator,
      Equipment: advancedValidator && validator,
      ProductQuality: advancedValidator,
      ExistingPlant: advancedValidator,
      FinalParameterAdjustments: validator,
    };

    const firstErrorKey = Object.entries(canMove).find(([, value]) => !value)?.[0];

    if (canMove[data.Name] || firstErrorKey === data.Name) {
      setSelectedPanel(data.Id);
      setIxLefNametpanel(data.Value);
    } else {
      if (
        ["ProductQuality", "AdvancedRegeneration", "ExistingPlant"].includes(data.Name) &&
        canMove["RegenerationConditions"]
      ) {
        setIxLefNametpanel("Regeneration Conditions");
      }
      if (specialCondition) {
        setIsLocationError(true);
      } else {
        const errorPannelName = IxdLeftMenu.find(item => item.Name === firstErrorKey)?.Value;
        setErrorInPanel(errorPannelName);
        setIsError(true);
      }
    }

    try {
      SaveUpdatedData();
    } catch {
      Logger.error("Error: Fetch IXDetails data based on ixLeftpanel selection");
    }
  };

  const componentMap = {
    1: IXInitialization,
    2: VesselRegenerationSystem,
    3: ResinSelection,
    4: RegenerationConditions,
    5: AdvancedRegeneration,
    6: Equipment,
    7: ProductQualityRegenerantDose,
    8: ExistingPlantDescription,
    9: FinalParameterAdjustment,
  };

  const getSelectedPanel = id => {
    const Component = componentMap[id];
    return Component ? (
      <div>
        <Component />
      </div>
    ) : null;
  };
  const headerIXMenuIconStatus = useSelector(state => state.IXStore.tabletMenuIcon);
  const [tabletView, setTabletView] = useState(false);
  const handleResize = () => {
    if (window.innerWidth <= 1200) {
      setTabletView(true);
      dispatch(updateTabletIXMenuIcon(tabletView));
    } else {
      setTabletView(false);
      dispatch(updateTabletIXMenuIcon(tabletView));
    }
  }; //show side menu for width >=1300

  useEffect(() => {
    handleResize(); // set initial state based on window size
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabletView]);
  const handleCloseSideMenu = () => {
    dispatch(updateIXMenuIconHeader(!headerIXMenuIconStatus));
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/home");
    dispatch(updateTabAvailable({ FeedSetup: false, IXD: false }));
    dispatch(updateTabAvailableForUF({ FeedSetup: false, UF: false }));
  };
  return (
    <>
      <IXDStyled tabletView={tabletView} headerMenuIconStatus={headerIXMenuIconStatus}>
        <Tabs>
          <div className='ixd-steps-column tablist_overlay'>
            <div className='wrapper'>
              {tabletView && (
                <div className='tablet_menu_view'>
                  <div>
                    <button className='close_icon_btn' onClick={handleCloseSideMenu}>
                      <TabletCloseMenuIcon />
                    </button>
                  </div>
                  <WaveProBrandLogo handleNavigate={handleNavigate} />
                </div>
              )}

              <TabList>
                {ixStoreObj.showFinalParameterTab
                  ? IxdLeftMenuFinal?.map(data => (
                      <div className={`tab ${selectedPanel == data.Id ? "selectedTab" : ""}`} key={data.Id}>
                        <h4 onClick={() => updateIXLeftpanel(data)}>{data.Value}</h4>
                      </div>
                    ))
                  : IxdLeftMenu?.map(data => (
                      <div className={`tab ${selectedPanel == data.Id ? "selectedTab" : ""}`} key={data.Id}>
                        <h4 onClick={() => updateIXLeftpanel(data)}>{data.Value}</h4>
                      </div>
                    ))}
              </TabList>
            </div>
          </div>
          <div className='ixd__tabs__details__view'>{getSelectedPanel(selectedPanel)}</div>
        </Tabs>
        {isError && (
          <ProjectErrorPopup
            show={isError}
            close={() => {
              setIsError(false);
            }}
            message={`The ${errorInPanel} is not correctly specified`}
          />
        )}
        {isLocationError && (
          <ProjectErrorPopup
            show={isLocationError}
            close={() => {
              setIsLocationError(false);
            }}
            message='The IX Degasification Location is not correctly specified'
          />
        )}
      </IXDStyled>
    </>
  );
};

export default IXD;
