/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import useFPAValidation from "@hooks/useFPAValidation";
import useUnitConversion from "@hooks/useUnitConversion";

import CloseCircleGreenIcon from "@common/icons/CloseCircleGreenIcon";
import CloseCircleRedIcon from "@common/icons/CloseCircleRedIcon";
import CloseCircleWarningIcon from "@common/icons/CloseCircleWarningIcon";
import CrossCircleIcon from "@common/icons/CrossCircleIcon";
import InfoIcon from "@common/icons/InfoIcon";
import MandatoryFieldIcon from "@common/icons/MandatoryFieldIcon";
import StyledCard from "@common/styles/components/cards/CustomCard";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import IconWithTooltip from "@common/styles/components/headings/IconWithTooltip";
import InputReferenceText from "@common/styles/components/headings/InputReferenceText";
import InputWithIcon from "@common/styles/components/inputs/InputWithIcon";
import InputWithSelect from "@common/styles/components/inputs/InputWithSelect";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomRadio from "@common/styles/components/radios/CustomRadio";
import { colors } from "@common/styles/Theme";
import Loader from "@common/utils/Loader";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import { IXInitializationRanges, OPERATING_CYCLE_OPTIONS } from "./constants/IXDConstants";
import Demineralization from "./IXMB/Demineralization";
import WaterChemistryAdjustment from "./IXMB/WaterChemistryAdjustment";
import EvaluateExistingPlantPopup from "./EvaluateExistingPlantPopup";
import { updateCaseFlag, updateExistingNew, updateIXStore, updateModeling, updateNewDesignExist } from "./IXDSlice";
import IXInitializationStyled from "./IXInitializationStyled";

function sum() {
  const getValue = id => parseInt(document.getElementById(id).value) || 0;
  const result = getValue("txtTrainsOnline") + getValue("txtTrainsSubsidy");
  return isNaN(result) ? 0 : result;
}
const IXInitialization = () => {
  const [isHovered, setIsHovered] = useState();
  const { updateParameterSettings } = useFPAValidation();
  const { feedFlowRate, productFlowRate, selectedEndNode } = useSelector(state => state.processDiagramSlice);
  const [Flowrate, setFlowrate] = useState("no_bufferTank_ind");
  const [TrainsTotal, setTrainsTotal] = useState(0);
  const [Automatic, setAutomatic] = useState(0);
  const [isFocused, setIsFocused] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();
  const ixStore = useSelector(state => state.IXStore.data);
  const loader = useSelector(state => state.cardlist.loader);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const unit = useSelector(state => state.projectInfo?.projectConfig?.unitConfig);
  const { cationResin, anionResin } = useSelector(state => state.IXStore.data);
  const { convertUnit } = useUnitConversion();

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      setIsError(false);
    }
  };
  const handleMouseLeave = () => {
    setIsHovered();
  };
  const handleFocus = ({ target }) => setIsFocused(target.id);

  const handleBlur = event => {
    const { name, value } = event.target;

    const getMinMaxValue = (unit, min, max) => ({
      min: unit.selectedUnits[10] === "BV/h" ? min : convertUnit(min, 10, "BV/h", 2),
      max: unit.selectedUnits[10] === "BV/h" ? max : convertUnit(max, 10, "BV/h", 2),
    });

    const { min: MinValue, max: MaxValue } = getMinMaxValue(unit, 5, 60);

    let valueIsSafe = false;

    const handleInputChange = (name, val, min, max, message) => {
      const parsedValue = parseFloat(val);
      if (parsedValue < min || parsedValue > max || isNaN(parsedValue)) {
        setIsError(true);
        setMessage(message);
        setTimeout(() => {
          event.target.focus();
        }, 0);
      } else {
        valueIsSafe = true;
        setIsError(false);

        const newValue = parsedValue.toFixed(Math.min(7, (parsedValue.toString().split(".")[1] || "").length));
        dispatch(updateIXStore({ ...ixStore, [name]: newValue }));
        updateParameterSettings();
      }
    };

    const inputConfig = {
      space_velocity_txt: {
        min: parseFloat(MinValue),
        max: parseFloat(MaxValue),
        message: "Please Enter Correct Range of the Specific Velocity !",
      },
      operatingCycle_Lenght_txt: {
        min: ixStore?.hrs_Day_ind
          ? IXInitializationRanges.operatingCycle_Lenght_txtInDays.min
          : IXInitializationRanges.operatingCycle_Lenght_txt.min,
        max: ixStore?.hrs_Day_ind
          ? IXInitializationRanges.operatingCycle_Lenght_txtInDays.max
          : IXInitializationRanges.operatingCycle_Lenght_txt.max,
        message: "Please Enter Correct Range of the operating Cycle !",
      },
      bypassed: {
        min: IXInitializationRanges.bypassed.min,
        max: IXInitializationRanges.bypassed.max,
        message: "Please Enter Correct Range of the Bypassed!",
      },
      trains_Online: {
        min: IXInitializationRanges.trains_Online.min,
        max: IXInitializationRanges.trains_Online.max,
        message: "Please Enter Correct Range of the Trains-Online!",
      },
      trains_StandBy: {
        min:
          ixStore.trains_Online?.toString() === "1"
            ? IXInitializationRanges.trains_StandBy.min
            : IXInitializationRanges.trains_StandBy.min_train_online,
        max: IXInitializationRanges.trains_StandBy.max,
        message: "Please Enter Correct Range of the Trains Standby!",
      },
    };

    const config = inputConfig[name];
    if (config) {
      handleInputChange(name, value, config.min, config.max, config.message);
    }

    if (valueIsSafe && name === "txtautomatic") {
      setAutomatic(value);
      dispatch(updateIXStore({ ...ixStore, [name]: parseFloat(value).toFixed(2) }));
    }
  };

  const NewPlantButtonClick = () => {
    dispatch(updateNewDesignExist(false));
    dispatch(updateCaseFlag(false));
    dispatch(updateModeling("NewPlant"));
    dispatch(
      updateIXStore({
        ...ixStore,
        ["newPlant_ind"]: true,
        ["evaluteExisting_ind"]: false,
        ["plant_to_Upcore_ind"]: false,
        ["bufferTank_ind"]: false,
        ["no_Buffer_Tank_ind"]: true,
        ["evaluateExisiting_ind_RuntimeFixed"]: true,
        ["evaluateExisiting_ind_RuntimeOptimized"]: false,
      }),
    );
    updateParameterSettings();
  };

  const EvaluteExistButtonClick = () => {
    dispatch(updateModeling("EvaluateExisting"));
    dispatch(
      updateIXStore({
        ...ixStore,
        ["newPlant_ind"]: false,
        ["evaluteExisting_ind"]: true,
        ["plant_to_Upcore_ind"]: false,
        ["bufferTank_ind"]: false,
        ["no_Buffer_Tank_ind"]: true,
        ["evaluateExisiting_ind_RuntimeFixed"]: false,
        ["evaluateExisiting_ind_RuntimeOptimized"]: true,
      }),
    );
    updateParameterSettings();
  };
  const PlanttoUpcoreButtonClick = () => {
    dispatch(updateModeling("RetrofitPlant"));
    dispatch(updateCaseFlag(false));
    dispatch(
      updateIXStore({
        ...ixStore,
        ["newPlant_ind"]: false,
        ["evaluteExisting_ind"]: false,
        ["plant_to_Upcore_ind"]: true,
      }),
    );
    dispatch(updateNewDesignExist(false));
    updateParameterSettings();
  };
  const changeBufferRadio = e => {
    const isBufferTank = e.target.name === "bufferTank_ind";
    dispatch(
      updateIXStore({
        ...ixStore,
        bufferTank_ind: isBufferTank,
        no_Buffer_Tank_ind: !isBufferTank,
      }),
    );
    setFlowrate(e.target.name);
    updateParameterSettings();
  };
  const changeRunTimeRadio = e => {
    const isRuntimeFixed = e.target.name === "evaluateExisiting_ind_RuntimeFixed";
    dispatch(
      updateIXStore({
        ...ixStore,
        evaluateExisiting_ind_RuntimeFixed: isRuntimeFixed,
        evaluateExisiting_ind_RuntimeOptimized: !isRuntimeFixed,
      }),
    );
    updateParameterSettings();
  };

  const txtChangeEvalute = e => {
    dispatch(updateIXStore({ ...ixStore, [e.target.name]: parseFloat(e.target.value) }));
    updateParameterSettings();
  };
  const txtChange = e => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value).toFixed(0);
    if (name === "trains_total") {
      setTrainsTotal(value);
    } else if (name === "txtautomatic") {
      setAutomatic(value);
    } else {
      setTrainsTotal(sum());
    }
    if (name === "trains_Online") {
      const range = value?.toString() == "1" ? 0 : 1;
      dispatch(updateIXStore({ ...ixStore, [name]: parsedValue, trains_StandBy: range }));
    } else {
      dispatch(updateIXStore({ ...ixStore, [name]: parsedValue }));
    }
    updateParameterSettings();
  };
  const handleSelectChange = e => {
    const isHours = e.target.value === "Hours";
    let newValue = ixStore.operatingCycle_Lenght_txt;

    if (!isHours && ixStore.operatingCycle_Lenght_txt > 417) {
      newValue = parseFloat(ixStore.operatingCycle_Lenght_txt / 24).toFixed(7);
    }
    dispatch(
      updateIXStore({
        ...ixStore,
        hrs_Day_ind: isHours,
        operatingCycle_Lenght_txt: newValue,
      }),
    );
    updateParameterSettings();
  };
  const handleOpenPopup = () => {
    if (cationResin === null && anionResin === null) {
      dispatch(updateNewDesignExist(true));
      dispatch(updateCaseFlag(true));
      dispatch(updateExistingNew("true"));
    } else {
      setOpenPopup(true);
    }
    updateParameterSettings();
  };

  const handleErrorPopupClose = () => setIsError(false);

  return !loader ? (
    <>
      <IXInitializationStyled className='g-0'>
        <div className='modeling-demineralization-column'>
          <StyledCard className='modeling-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Modeling Objective'
                mandatoryIcon={<MandatoryFieldIcon />}
              />
              <Card.Title onMouseEnter={() => setIsHovered("Modeling")} onMouseLeave={handleMouseLeave}>
                <IconWithTooltip
                  label='New: WAVE computes system design. Evaluate: User provides system design, WAVE estimates performance.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body className='modeling_objective_radio_group'>
              <CustomRadio
                disabled={false}
                type='radio'
                id='radioNewPlant'
                onClick={NewPlantButtonClick}
                checked={ixStore.newPlant_ind === true}
                name='radio1'
                label='New Plant Design'
                className='ix_initialization_radio'
              />
              <CustomRadio
                type='radio'
                id='evaluateRadio'
                disabled
                onClick={EvaluteExistButtonClick}
                checked={ixStore.evaluteExisting_ind == true}
                name='radio1'
                label='Evaluate Existing Plant'
                onChange={handleOpenPopup}
                className='ix_initialization_radio'
              />
              <EvaluateExistingPlantPopup show={openPopup} close={() => setOpenPopup(false)} />
              <CustomRadio
                disabled={true}
                type='radio'
                id='retrofitRadio'
                onClick={PlanttoUpcoreButtonClick}
                checked={ixStore.plant_to_Upcore_ind == true}
                name='radio1'
                label='Retrofit Plant to UPCOREᵀᴹ'
                className='ix_initialization_radio'
              />
            </Card.Body>
          </StyledCard>

          <Demineralization />
          <WaterChemistryAdjustment />
        </div>
        <div className='train-configuration-column'>
          <StyledCard className='train-configuration-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Train Configuration'
                mandatoryIcon={<MandatoryFieldIcon />}
              />
              <Card.Title onMouseEnter={() => setIsHovered("Train")} onMouseLeave={handleMouseLeave}>
                <IconWithTooltip
                  label='Choose number of trains and system level hydraulic flow parameters.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className='trains-wrapper'>
                <div className='trains-online'>
                  <CustomLabel label='# of Trains-Online' />
                  <InputWithIcon
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    unitBgColor='transparent'
                    disabled={false}
                    isWarning={false}
                    isError={
                      ixStore.trains_Online < 1 || ixStore.trains_Online > 20 || isNaN(parseInt(ixStore.trains_Online))
                    }
                    onClick
                    inputText={
                      ixStore.trains_Online < 1 ? (
                        <CloseCircleRedIcon />
                      ) : parseInt(ixStore.trains_Online) > 20 ? (
                        <CloseCircleWarningIcon />
                      ) : (
                        <CloseCircleGreenIcon />
                      )
                    }
                    type='number'
                    id='txtTrainsOnline'
                    onChange={txtChange}
                    name='trains_Online'
                    value={ixStore.trains_Online}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    isFocused={isFocused === "txtTrainsOnline"}
                  />
                  <InputReferenceText refText='Ranges 1-20' />
                </div>
                <div className='trains-subsidy'>
                  <CustomLabel label='# of Trains-Standby' />
                  <InputWithIcon
                    unitBgColor='transparent'
                    disabled={false}
                    isError={
                      ixStore.trains_Online == 1
                        ? ixStore.trains_StandBy < 0 ||
                          ixStore.trains_StandBy > 5 ||
                          isNaN(parseInt(ixStore.trains_StandBy))
                        : ixStore.trains_StandBy < 1 ||
                          ixStore.trains_StandBy > 5 ||
                          isNaN(parseInt(ixStore.trains_StandBy))
                    }
                    isWarning={false}
                    onClick
                    inputText={<CloseCircleGreenIcon />}
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    type='number'
                    id='txtTrainsSubsidy'
                    name='trains_StandBy'
                    onChange={txtChange}
                    value={ixStore.trains_StandBy}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    isFocused={isFocused === "txtTrainsSubsidy"}
                  />
                  <InputReferenceText refText={ixStore.trains_Online == "1" ? "Ranges 0-5" : "Ranges 1-5"} />
                </div>
              </div>
              <div className='radio-wrapper' hidden={parseInt(ixStore.trains_StandBy) >= 1 ? true : false}>
                <CustomRadio
                  type='radio'
                  id='useFlowSystem'
                  name='bufferTank_ind'
                  value={ixStore.bufferTank_ind}
                  onClick={changeBufferRadio}
                  checked={ixStore.bufferTank_ind == true}
                  label='Use Flowrate as system daily avg'
                  className='ix_initialization_radio'
                />
                <CustomRadio
                  type='radio'
                  id='useFlowVessel'
                  name='no_bufferTank_ind'
                  value={ixStore.no_Buffer_Tank_ind}
                  onClick={changeBufferRadio}
                  checked={ixStore.no_Buffer_Tank_ind == true}
                  label='Use Flowrate for vessel design'
                  className='ix_initialization_radio'
                />
              </div>
              <div className='trains-wrapper'>
                <div className='trains-total'>
                  <CustomLabel label='# of Trains-Total' disabled={Flowrate === "no_bufferTank_ind" ? true : true} />
                  <InputWithIcon
                    unitBgColor='transparent'
                    type='text'
                    disabled={Flowrate === "no_bufferTank_ind" ? true : true}
                    onChange={txtChange}
                    name='trains_total'
                    id='txtTrainsTotal'
                    value={
                      TrainsTotal == 0
                        ? parseInt(ixStore.trains_Online) + parseInt(ixStore.trains_StandBy)
                        : TrainsTotal
                    }
                    inputText={<CrossCircleIcon />}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    isFocused={isFocused === "txtTrainsTotal"}
                    isError={false}
                    isWarning={false}
                  />
                  <InputReferenceText />
                </div>
                <div className='bypassed'>
                  <CustomLabel
                    label='% Bypassed'
                    disabled={ixStore.bufferTank_ind ? false : ixStore.trains_StandBy <= 1}
                  />
                  <InputWithIcon
                    onKeyDown={evt =>
                      ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) && evt.preventDefault()
                    }
                    type='number'
                    id='bypassed'
                    disabled={ixStore.bufferTank_ind ? false : ixStore.trains_StandBy == 0}
                    name='bypassed'
                    onClick
                    onChange={txtChange}
                    value={ixStore.bypassed || 0}
                    inputText={<CrossCircleIcon />}
                    unitBgColor='transparent'
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    isFocused={isFocused === "bypassed"}
                    isError={ixStore.bypassed < 0 || ixStore.bypassed > 95}
                    isWarning={false}
                  />
                  <InputReferenceText refText='Ranges 0 – 95%' />
                </div>
              </div>
            </Card.Body>
          </StyledCard>
        </div>
        <div className='feed-flow-regeneration-column'>
          <StyledCard className='feed-flow-card'>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label={selectedEndNode == "startNode" ? "IX Feed Flow Rate" : "IX Product Flow Rate"}
              />
              <Card.Title onMouseEnter={() => setIsHovered("IXFeed")} onMouseLeave={handleMouseLeave}>
                <IconWithTooltip
                  label='Estimated IX Feed or Product flow, based on user input and estimated recoveries of other icons.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className='automatic'>
                <CustomLabel label='Automatic' disabled={ixStore.newPlant_ind ? true : false} />
                <InputWithText
                  type='number'
                  isError={false}
                  id='txtautomatic'
                  disabled={true}
                  name='txtautomatic'
                  onChange={txtChange}
                  inputText={unit.selectedUnits[1]}
                  value={
                    Automatic === 0 ? (selectedEndNode == "startNode" ? feedFlowRate : productFlowRate) : Automatic
                  }
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isFocused={isFocused === "txtautomatic"}
                />
                <InputReferenceText refText='' />
              </div>
            </Card.Body>
          </StyledCard>
          <StyledCard className='regeneration-card' disabled={true}>
            <Card.Header>
              <CustomHeading
                fontFamily='NotoSansRegular'
                fontSize='14px'
                fontWeight='400'
                color={colors.PrimaryDarkAquaMarine}
                label='Regeneration Frequency'
                mandatoryIcon={<MandatoryFieldIcon />}
              />
              <Card.Title onMouseEnter={() => setIsHovered("Regeneration")} onMouseLeave={handleMouseLeave}>
                <IconWithTooltip
                  label='Choose whether run time is fixed, or if WAVE should solve for maximum run time meeting objectives.'
                  icon={<InfoIcon />}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <div className='operating-cycle'>
                <CustomRadio
                  type='radio'
                  id='runTimeRadio'
                  value={ixStore.evaluateExisiting_ind_RuntimeFixed}
                  checked={ixStore.evaluateExisiting_ind_RuntimeFixed}
                  name='evaluateExisiting_ind_RuntimeFixed'
                  onChange={changeRunTimeRadio}
                  label='Run time is fixed'
                  className='ix_initialization_radio'
                />
                <CustomLabel
                  label='Length of Operating Cycles'
                  disabled={!ixStore.evaluateExisiting_ind_RuntimeFixed}
                />
                <InputWithSelect
                  type='number'
                  id='operatingCycle_Lenght_txt'
                  disabled={!ixStore.evaluateExisiting_ind_RuntimeFixed}
                  onChange={txtChangeEvalute}
                  name='operatingCycle_Lenght_txt'
                  value={ixStore.operatingCycle_Lenght_txt}
                  selectedValue={ixStore?.hrs_Day_ind == false ? "Days" : "Hours"}
                  onValueChange={handleSelectChange}
                  isError={
                    ixStore?.hrs_Day_ind === true
                      ? 2400 < ixStore.operatingCycle_Lenght_txt ||
                        3 > ixStore.operatingCycle_Lenght_txt ||
                        !ixStore.operatingCycle_Lenght_txt
                      : 100 < ixStore.operatingCycle_Lenght_txt ||
                        0.125 > ixStore.operatingCycle_Lenght_txt ||
                        !ixStore.operatingCycle_Lenght_txt
                  }
                  options={OPERATING_CYCLE_OPTIONS}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isFocused={isFocused === "operatingCycle_Lenght_txt"}
                />
                <InputReferenceText refText={ixStore?.hrs_Day_ind === false ? "Range 0.125–100" : "Range 3–2400"} />
              </div>
              <div className='specific-velocity'>
                <CustomRadio
                  isError={false}
                  type='radio'
                  id='radio1'
                  name='evaluateExisiting_ind_RuntimeOptimized'
                  value={ixStore.evaluateExisiting_ind_RuntimeOptimized}
                  checked={ixStore.evaluateExisiting_ind_RuntimeOptimized}
                  onChange={changeRunTimeRadio}
                  label='Run time is optimized'
                  disabled={false}
                  className='ix_initialization_radio'
                />
                <CustomLabel
                  label='Specific Velocity'
                  disabled={ixStore.evaluateExisiting_ind_RuntimeOptimized ? false : true}
                />
                {/* <InputGroup> */}
                <InputWithText
                  type='number'
                  id='space_velocity_txt'
                  disabled={ixStore.evaluateExisiting_ind_RuntimeOptimized ? false : true}
                  name='space_velocity_txt'
                  onChange={txtChangeEvalute}
                  value={ixStore.space_velocity_txt}
                  inputText={unit.selectedUnits[10]}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  isFocused={isFocused === "space_velocity_txt"}
                  isError={
                    ixStore.space_velocity_txt <
                      (unit.selectedUnits[10] === "BV/h" ? 5 : convertUnit(5, 10, "BV/h", 2)) ||
                    ixStore.space_velocity_txt >
                      (unit.selectedUnits[10] === "BV/h" ? 60 : convertUnit(60, 10, "BV/h", 2)) ||
                    !ixStore.space_velocity_txt
                  }
                />
                <InputReferenceText
                  refText={`Ranges ${unit.selectedUnits[10] === "BV/h" ? 5 : convertUnit(5, 10, "BV/h", 2)} - ${unit.selectedUnits[10] === "BV/h" ? 60 : convertUnit(60, 10, "BV/h", 2)}`}
                />
              </div>
            </Card.Body>
          </StyledCard>
        </div>
        {isError && <ProjectErrorPopup show={isError} close={handleErrorPopupClose} message={message} />}
      </IXInitializationStyled>
    </>
  ) : (
    <Loader />
  );
};

export default IXInitialization;
