import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import useUnitConversion from "@hooks/useUnitConversion";

import BoldTextElement from "../UFDiagramsUtils/BoldTextElement";
import TextElement from "../UFDiagramsUtils/TextElement";
import { getPumpValues } from "../UFDiagramsUtils/UFDiagramHelper";

const UFDiagramCIPPumpDetails = () => {
  const { unitConversionByName } = useUnitConversion();
  const { calcEngineData, data: UFStoreData } = useSelector(state => state.UFStore);
  const { unitConfig } = useSelector(state => state.projectInfo?.projectConfig);
  const [volumData, setVolumData] = useState({});

  useEffect(() => {
    const pressureValue = UFStoreData.cIPPipingPreDrop;
    const data = getPumpValues(unitConfig, calcEngineData, "flow_CIP_Pump", pressureValue, unitConversionByName);
    setVolumData(data);
  }, [unitConfig.selectedUnits, calcEngineData, getPumpValues]);

  return (
    <>
      <BoldTextElement x='1900' y='2900'>
        CIP Pump
      </BoldTextElement>
      <TextElement fill='#454545'>
        <tspan x='1900' y='3040'>
          {volumData.value}
        </tspan>
        <tspan x='1900' y='3180'>
          {volumData.pressure}
        </tspan>
      </TextElement>
    </>
  );
};

export default UFDiagramCIPPumpDetails;
