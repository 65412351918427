import { createSlice } from "@reduxjs/toolkit";

import { UF_MODULE_PER_RACK_RANGE } from "./constants/UFConstants";
import UfDefaultData from "./constants/UFDefaultValues.json";
import UFStoreInitialState from "./constants/UFStoreInitialState";
import { calculateUFFields } from "./UFConfiguration/UFHelper";

export const UFSlice = createSlice({
  name: "UFStore",
  initialState: UFStoreInitialState,
  reducers: {
    updateMenuIconHeader: (state, action) => {
      state.headerMenuIcon = action.payload;
    },
    updateTabletMenuIcon: (state, action) => {
      state.tabletMenuIcon = action.payload;
    },
    updateTabletIconStatus: (state, action) => {
      state.tabletMenuIconStatus = action.payload;
    },
    updateSliderValue: (state, action) => {
      state.sliderValue = action.payload;
    },
    updateMinMaxSliderValue: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    updateActiveUFModule: (state, action) => {
      state.activeUFModule = action.payload;
    },
    updateRecommendedConfiguration: (state, action) => {
      state.recommended_configs = action.payload;
    },
    updateCallCalcEngineUFConfig: (state, action) => {
      state.callCalcEngineUFConfig = action.payload;
    },
    updateIsForDrinkingWater: (state, action) => {
      state.isForDrinkingWater = action.payload;
      // state.isUfDataUpdated=true;
    },
    updateUFStandByOptions: (state, action) => {
      state.ufStandByOptions = action.payload;
      // state.isUfDataUpdated=true;
    },
    updateUFStorageTankOptions: (state, action) => {
      state.ufStorageTankOptions = action.payload;
    },
    updateUFStore: (state, action) => {
      state.data = action.payload;
    },
    updateUFDefaultInputRangeConfig: (state, action) => {
      //considered ufInputRangeConfig and ufInputRangeConfigByWaterType for all fields other than Filtrate Flux
      //considerd ufFluxGuideline for FiltrateFlux
      state.defaultInputRangeConfig = { ...state.defaultInputRangeConfig, ...action.payload };
    },
    updateUFStoreData: (
      { data: oldData, calcEngineDataRefreshCount: oldRefresh, ...oldState },
      { payload: { data, calcEngineDataRefreshCount, ...rest } },
    ) => ({
      ...oldState,
      data: { ...oldData, ...data },
      calcEngineDataRefreshCount: calcEngineDataRefreshCount ? oldRefresh + 1 : oldRefresh,
      ...rest,
    }),
    setRefreshDiagram: state => ({
      ...state,
      calcEngineDataRefreshCount: state.calcEngineDataRefreshCount + 1,
    }),
    setIsSysDiagramVisible: (state, { payload }) => ({
      ...state,
      isSysDesignVisible: payload,
    }),
    updateAdditionalData: (state, action) => {
      const { target, value } = action.payload;
      state.data[target] = value;
    },
    handleCalcEngineResponse: (state, action) => {
      state.calcEngineData = action.payload;
      state.calcEngineDataRefreshCount = -1;
      state.isRecommendationCalucalting = false;
      state.isUFConfigLoading = false;
    },
    handleOperatingFlux: (state, action) => {
      state.calcEngineData["flux_Filter_actual"] = action.payload?.flux_Filter_actual;
    },
    handleSameAsBackwash: (state, action) => {
      const flag = action.payload;
      const backWash1_CEB = state.defaultInputRangeConfig.backWash1_CEB;
      const backWash2_CEB = state.defaultInputRangeConfig.backWash2_CEB;

      state.data.drain = state.data.drain_backWash;
      state.data.ceb_AirScour = state.data.backwash_AirScour;
      state.data.backWash1_CEB =
        backWash1_CEB.minValue > state.data.backWash1_backWash || backWash1_CEB.maxValue < state.data.backWash1_backWash
          ? state.data.backWash1_CEB
          : state.data.backWash1_backWash;
      state.data.backWash2_CEB =
        backWash2_CEB.minValue > state.data.backWash2_backWash || backWash2_CEB.maxValue < state.data.backWash2_backWash
          ? state.data.backWash2_CEB
          : state.data.backWash2_backWash;
      if (flag) {
        state.data.forwardFlush = state.data.forwardFlush_backWash;
      }
    },
    handleCipDropdownData: (state, action) => {
      const { lstUFCIPWaterTypeVM, lstChemicalVM } = action.payload.data;
      const tempData = {
        mineral: [],
        alkali: [],
        organic: [],
        oxidant: [],
        oxidant2: [],
        disOxidant: [],
      };
      tempData.mineral = lstChemicalVM.filter(x => x.name === "Acid");
      tempData.alkali = lstChemicalVM.filter(x => x.name === "Base");
      tempData.organic = lstChemicalVM.filter(x => x.name === "Organic Acid");
      tempData.oxidant = lstChemicalVM.filter(x => x.name === "Oxidant");
      tempData.oxidant2 = lstChemicalVM.filter(x => x.name === "Surfactant");
      tempData.disOxidant = tempData.oxidant;
      state.cipDropdownData.waterType = lstUFCIPWaterTypeVM;
      state.cipDropdownData.chemicalType = tempData;
      state.cipDropdownData.isFetched = action.payload.isFetched;
    },
    calculateUFConfigFields: state => {
      const calculatedUFFields = calculateUFFields(state.data, state.activeUFModule);
      state.data = { ...state.data, ...calculatedUFFields };
    },
    updateWaterSubtypeFlag: (state, action) => {
      state.waterSubTypeFlag = action.payload;
    },
    resetUfData: state => {
      state.data = UfDefaultData;
      state.isCustomConfigAvail = false;
      state.isUfDataUpdated = false;
      state.calcEngineDataRefreshCount = -1;
      state.calcEngineData = {};
      state.recommended_configs = [];
      state.activeTab = "Design";
      state.sliderValue = UF_MODULE_PER_RACK_RANGE.max;
      state.sliderMin = UF_MODULE_PER_RACK_RANGE.min;
      state.sliderMax = UF_MODULE_PER_RACK_RANGE.max;
      state.ufChemicalAdjustment = UFStoreInitialState.ufChemicalAdjustment;
      state.selectedModuleCategory = UFStoreInitialState.selectedModuleCategory;
      state.ufProductFilter = UFStoreInitialState.ufProductFilter;
      state.isUFDetailsFetched = false;
      state.isSaveReportJson = false;
      state.isUFConfigLoading = false;
      state.activeUFModule = {};
    },
    setCustomAvail: (state, action) => {
      state.isCustomConfigAvail = action.payload;
    },
    setCustomOfflineTimePerUnit: (state, action) => {
      state.isOfflineTimeChanged = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setUfDataUpdate: (state, action) => {
      state.isUfDataUpdated = action.payload;
    },
    setUFChemicalAdjustment: (state, action) => {
      state.ufChemicalAdjustment = { ...state.ufChemicalAdjustment, ...action.payload };
    },
    setUFProductFilter: (state, action) => {
      state.ufProductFilter = { ...state.ufProductFilter, ...action.payload };
    },
  },
});

export const {
  updateUFStoreData,
  setRefreshDiagram,
  setIsSysDiagramVisible,
  updateActiveUFModule,
  updateRecommendedConfiguration,
  updateCallCalcEngineUFConfig,
  updateIsForDrinkingWater,
  updateUFStandByOptions,
  updateUFStorageTankOptions,
  calculateUFConfigFields,
  updateUFStore,
  updateUFDefaultInputRangeConfig,
  updateAdditionalData,
  handleCebDropdownData,
  handleSameAsBackwash,
  handleCipDropdownData,
  handleCalcEngineResponse,
  handleOperatingFlux,
  getOperatingFluxFromCalcEngine,
  generateConfigsWithoutStandBy,
  generateConfigsWithStandBy,
  updateWaterSubtypeFlag,
  updateSliderValue,
  resetUfData,
  updateTabletMenuIcon,
  updateTabletIconStatus,
  updateMenuIconHeader,
  setCustomAvail,
  setCustomOfflineTimePerUnit,
  setActiveTab,
  setUfDataUpdate,
  updateMinMaxSliderValue,
  setUFChemicalAdjustment,
  setUFProductFilter,
} = UFSlice.actions;
export default UFSlice.reducer;
