import { useMemo } from "react";
import { useSelector } from "react-redux";

const useProjectDetails = () => {
  const { data: projectData } = useSelector(state => state.projectInfo);

  const PROJECT_TECH = useMemo(() => (projectData ? projectData.Tchnology : ""), [projectData]);

  return { PROJECT_TECH };
};

export default useProjectDetails;
