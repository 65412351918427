import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UNITS } from "@constants/units.constant";

import useUFChemicalsHandler from "@hooks/useUFChemicalsHandler";
import useUFTechnologyStrings from "@hooks/useUFTechnologyStrings";
import useUnitConversion from "@hooks/useUnitConversion";

import { convertUptoDigits } from "@utils/appUtils";

import CustomCard from "@components/CustomCard";
import InputRanges from "@components/InputRanges";

import CustomRadioCheck from "@common/styles/components/checkboxs/CustomRadioCheck";
import CustomHeading from "@common/styles/components/headings/CustomHeading";
import CustomLabel from "@common/styles/components/headings/CustomLabel";
import InputWithText from "@common/styles/components/inputs/InputWithText";
import CustomSelect from "@common/styles/components/selects/CustomSelect";
import ToggleSwitch from "@common/styles/components/switches/CustomSwitch";

import ProjectErrorPopup from "../../modals/ProjectErrorPopup";

import { UF_CHEMICAL_SCREENS } from "./constants/UFConstants";
import CEBStyled from "./CEBStyled";
import { handleSameAsBackwash, setUfDataUpdate, updateUFStoreData } from "./UFSlice";
import UFSystemDesignDiagram from "./UFSystemDesignDiagram";
import { isIngeSelected } from "./ufUtils";

const CEB = () => {
  const dispatch = useDispatch();
  const cebData = useSelector(state => state.UFStore.data);

  const ufTechStrings = useUFTechnologyStrings();
  const { unitConversionByName } = useUnitConversion();
  const { isValueInPh, selectedTempUnit, getUFChemicalData, getChemicalRanges } = useUFChemicalsHandler();

  const [inputError, setInputError] = useState();
  const [DISABLED_INPUTS, setDISABLED_INPUTS] = useState([]);

  const { ufInputRangeConfig, ufInputRangeConfigByWaterType, bwProtocol } = useSelector(state => state.UFStore);
  const { waterType, chemicalType } = useSelector(state => state.UFStore.cebDropdownData);

  const { waterSubTypeID, tempDesign } =
    useSelector(state => state.Feedsetupdetailsdatapanel?.streamData?.lstrequestsavefeedwater[0]?.streams[0]) || {};

  const technologyID = useSelector(state => state.UFStore.data.pUFTechnologyID);
  const selectedUFModule = useSelector(state => state.UFStore.activeUFModule);
  const bwProtocolSelectedProtocol = useSelector(state => state.UFStore?.data?.uFBWProtocolID);
  const selectedFilter = bwProtocol?.filter(item => item.ufbwProtocolID === parseInt(bwProtocolSelectedProtocol));
  const isUXASelected =
    selectedUFModule?.newModuleLongName?.indexOf("UXA") >= 0 && selectedFilter.length > 0
      ? selectedFilter[0]?.protocolName?.includes("UXA")
      : false;

  const provideRange = (label, hardCoded) => {
    let foundRange = ufInputRangeConfigByWaterType.find(
      item => item.label == label && item.waterSubType == waterSubTypeID,
    );
    if (!foundRange) {
      foundRange = ufInputRangeConfig.find(item => item.label == label);
      if (!foundRange) {
        return hardCoded;
      } else {
        return foundRange;
      }
    } else {
      return foundRange;
    }
  };
  const isIngeSelectedMemo = useMemo(() => isIngeSelected(technologyID), [technologyID]);
  const validations = useMemo(
    () => ({
      ...getChemicalRanges(isValueInPh ? "ph" : "mgL", UF_CHEMICAL_SCREENS.CEB),
      chemicalSoakingDuration_CEB: provideRange("CEB Soak Time", { minValue: 5, maxValue: 60 }),
      ceb_AirScour: provideRange("t_AS", { minValue: 10, maxValue: 60 }),
      drain: provideRange("t_drain", { minValue: 0, maxValue: 60, defaultValue: 30 }),
      backWash1_CEB: provideRange("t_CEB1&2", { minValue: 15, maxValue: 60 }),
      backWash2_CEB: provideRange("t_CEB1&2", { minValue: 15, maxValue: 60 }),
      forwardFlush: provideRange("t_FF", { minValue: 15, maxValue: 120 }),
      bwRinseDrainTop: provideRange("t_CEB_Rinse1&2", { minValue: 15, maxValue: 60, defaultValue: 45 }),
      bwRinseDrainBottom: provideRange("t_CEB_Rinse1&2", { minValue: 15, maxValue: 60, defaultValue: 45 }),
    }),
    [isValueInPh, waterSubTypeID],
  );

  const [isFocused, setIsFocused] = useState(null);

  const closeErrorMessag = () => setInputError();
  const handleFocus = id => setIsFocused(id);
  const updateStoreData = data => dispatch(updateUFStoreData({ data }));

  //updated ceb temp based on feed setup design temperature
  const setDesignTemp = () => updateStoreData({ cEBTemperature: tempDesign || 0 });

  useEffect(() => {
    const disabledInputsSet = new Set(
      isIngeSelectedMemo
        ? [ufTechStrings.airScour, ufTechStrings.drain, ufTechStrings.forwardFlush]
        : [ufTechStrings.bWRinseDrainBottom, ufTechStrings.bWRinseDrainTop],
    );

    if (isUXASelected) {
      disabledInputsSet.add(ufTechStrings.drain);
      disabledInputsSet.add(ufTechStrings.airScour);
      dispatch(updateStoreData({ ceb_AirScour: cebData.backWash1_CEB }));
    } else {
      disabledInputsSet.delete(ufTechStrings.drain);
      disabledInputsSet.delete(ufTechStrings.airScour);
    }

    setDISABLED_INPUTS(Array.from(disabledInputsSet));
  }, [technologyID, isUXASelected]);

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      closeErrorMessag();
    }
  };

  useEffect(() => {
    setDesignTemp();
  }, [tempDesign]);

  const handleEnableandDisable = e => {
    const { name, checked, id } = e.target;
    if (name == "sameAsBackwash_Ind" && checked) {
      dispatch(handleSameAsBackwash(true));
    } else {
      const transferData = {
        target: {
          name: `${id}ChemId`,
          value: checked ? chemicalType[id][0]?.id : 0,
          id: id,
        },
      };
      if (name != "sameAsBackwash_Ind") {
        handleSelect(transferData);
      }
    }
    updateStoreData({ [name]: checked });
    dispatch(setUfDataUpdate(true));
  };

  const handleSelect = ({ target }) => {
    const { value, name, id } = target;
    const chemicalValue = getUFChemicalData(id, value, "CEB");
    const eventData = { target: { id, value: chemicalValue, name: `${id}Value` } };
    handleInputChange(eventData);
    dispatch(
      updateUFStoreData({ data: { [name]: value.toString() }, calcEngineDataRefreshCount: 1, isUfDataUpdated: true }),
    );
  };

  //control all input field
  const handleInputChange = ({ target: { name, value } }) => {
    if (!isNaN(value)) {
      const updatedData = { [name]: value };
      if (isUXASelected && name === "backWash1_CEB") {
        updatedData.ceb_AirScour = value;
      }
      updateStoreData(updatedData);
      dispatch(setUfDataUpdate(true));
    }
  };

  const handleBlur = (e, label) => {
    const { name, value } = e.target;
    const { minValue, maxValue } = validations[name];
    if (value < minValue || value > maxValue) {
      const errorMsg = `The ${label} value entered is outside the allowed range 
                        (${convertUptoDigits(minValue)} to ${convertUptoDigits(maxValue)}). Please revise your input.`;
      setInputError(errorMsg);
      setTimeout(() => e.target.focus(), 0);
    } else {
      dispatch(updateUFStoreData({ data: { [name]: convertUptoDigits(value) }, calcEngineDataRefreshCount: 1 }));
      setIsFocused(null);
      closeErrorMessag();
    }
  };

  const checkError = id => !(validations[id]?.minValue <= cebData[id] && validations[id]?.maxValue >= cebData[id]);
  const isChemicalDropdownDisabled = id => !chemicalType || !chemicalType[id] || chemicalType[id].length === 0;
  const getValue = field => (isFocused === field ? cebData[field] : convertUptoDigits(cebData[field]));

  return (
    <>
      <CEBStyled className='g-0'>
        <UFSystemDesignDiagram />
        <div className='ceb-temp-water'>
          <CustomCard
            className='ceb-temp'
            header={ufTechStrings.cebTemprature}
            tooltipLabel='Informational, it is assumed CEB will use water at the design temperature.'
          >
            <div className='ceb-temp-div'>
              <CustomLabel label={ufTechStrings.digramForFeedWater} />
              <InputWithText
                className='ceb-temp-input'
                type='number'
                disabled
                inputText={selectedTempUnit}
                placeholder='0.00'
                value={unitConversionByName(tempDesign, selectedTempUnit, UNITS.celsius)}
                isFocused={isFocused === 0}
                onBlur={e => handleBlur(e, "")}
                onFocus={() => handleFocus(2)}
              />
            </div>
          </CustomCard>
          <CustomCard
            className='water-source'
            header={ufTechStrings.waterSource}
            tooltipLabel='Select source of water for cleaning protocols, RO permeate available if RO in design.'
          >
            <div>
              <CustomLabel label={ufTechStrings.cebWaterSource} />
              <CustomSelect
                onChange={handleSelect}
                name='uFCEBWaterTypeID'
                value={cebData.uFCEBWaterTypeID}
                id='waterType'
              >
                {waterType.map(item => (
                  <option key={item.uFCEBWaterTypeID} value={item.uFCEBWaterTypeID}>
                    {item.cEBWaterTypeName}
                  </option>
                ))}
              </CustomSelect>
            </div>
          </CustomCard>
        </div>
        <div className='alkali-acid-dis'>
          <CustomCard
            className='acid-card'
            header={ufTechStrings.acidCEB}
            tooltipLabel='Select mineral acid and organic acid chemical reagents and target doses or pH'
          >
            <div className='ceb-water-source'>
              <div className='label-switch'>
                <CustomHeading label={ufTechStrings.mineralAcid} />
                <ToggleSwitch
                  small
                  id='mineral'
                  name='mineralEnabled_Ind_CEB'
                  disabled={isChemicalDropdownDisabled("mineral")}
                  checked={cebData.mineralEnabled_Ind_CEB}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='mineral'
                    disabled={!cebData.mineralEnabled_Ind_CEB}
                    value={cebData.mineralChemId}
                    name='mineralChemId'
                    onChange={handleSelect}
                  >
                    {!cebData.mineralEnabled_Ind_CEB && <option value={0}></option>}
                    {chemicalType?.mineral?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='mineral'
                    name='mineralValue'
                    disabled={!cebData.mineralEnabled_Ind_CEB}
                    value={getValue("mineralValue")}
                    onChange={handleInputChange}
                    isError={checkError("mineralValue")}
                    inputText={cebData.mineralValueInPh_Ind ? "pH" : "mg/L"}
                    isFocused={isFocused === "mineralValue"}
                    onBlur={e => handleBlur(e, ufTechStrings.mineralAcid)}
                    onFocus={() => handleFocus("mineralValue")}
                  />
                  <InputRanges ranges={validations.mineralValue} />
                </div>
              </div>
            </div>
            <div className='organic-acid'>
              <div className='label-switch'>
                <CustomHeading label={ufTechStrings.organicAcid} />
                <ToggleSwitch
                  small
                  id='organic'
                  name='organicEnabled_Ind_CEB'
                  disabled={isChemicalDropdownDisabled("organic")}
                  checked={cebData.organicEnabled_Ind_CEB}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='organic'
                    disabled={!cebData.organicEnabled_Ind_CEB}
                    value={cebData.organicChemId}
                    name='organicChemId'
                    onChange={handleSelect}
                  >
                    {!cebData.organicEnabled_Ind_CEB && <option value={0}></option>}
                    {chemicalType?.organic?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    id='organic'
                    disabled={!cebData.organicEnabled_Ind_CEB}
                    value={getValue("organicValue")}
                    name='organicValue'
                    onChange={handleInputChange}
                    isError={checkError("organicValue")}
                    inputText='mg/L'
                    isFocused={isFocused === "organicValue"}
                    onBlur={e => handleBlur(e, ufTechStrings.organicAcid)}
                    onFocus={() => handleFocus("organicValue")}
                  />
                  <InputRanges ranges={validations.organicValue} />
                </div>
              </div>
            </div>
          </CustomCard>
          <CustomCard
            className='alkali-card'
            header={ufTechStrings.alkaliOxidantCEB}
            tooltipLabel='Select alkali and oxidant chemical reagents and target doses or pH.'
          >
            <div className='alkali'>
              <div className='label-switch'>
                <CustomHeading label={ufTechStrings.alkali} />
                <ToggleSwitch
                  small
                  id='alkali'
                  name='alkaliEnabled_Ind_CEB'
                  disabled={isChemicalDropdownDisabled("alkali")}
                  checked={cebData.alkaliEnabled_Ind_CEB}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    disabled={!cebData.alkaliEnabled_Ind_CEB}
                    value={cebData.alkaliChemId}
                    name='alkaliChemId'
                    id='alkali'
                    onChange={handleSelect}
                  >
                    {!cebData.alkaliEnabled_Ind_CEB && <option value={0}></option>}
                    {chemicalType?.alkali.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    disabled={!cebData.alkaliEnabled_Ind_CEB}
                    id='alkali'
                    name='alkaliValue'
                    onChange={handleInputChange}
                    value={getValue("alkaliValue")}
                    isError={checkError("alkaliValue")}
                    inputText={cebData.alkaliValueInPh_Ind ? "pH" : "mg/L"}
                    placeholder='0.00'
                    isFocused={isFocused === "alkaliValue"}
                    onBlur={e => handleBlur(e, ufTechStrings.alkali)}
                    onFocus={() => handleFocus("alkaliValue")}
                  />
                  <InputRanges ranges={validations.alkaliValue} />
                </div>
              </div>
            </div>
            <div className='oxidant'>
              <div className='label-switch'>
                <CustomHeading label={ufTechStrings.oxidant} />
                <ToggleSwitch
                  small
                  id='oxidant'
                  name='oxidantEnabled_Ind_CEB'
                  disabled={isChemicalDropdownDisabled("oxidant")}
                  checked={cebData.oxidantEnabled_Ind_CEB}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    disabled={!cebData.oxidantEnabled_Ind_CEB}
                    value={cebData.oxidantChemId}
                    id='oxidant'
                    name='oxidantChemId'
                    onChange={handleSelect}
                  >
                    {!cebData.oxidantEnabled_Ind_CEB && <option value={0}></option>}
                    {chemicalType?.oxidant.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    type='number'
                    disabled={!cebData.oxidantEnabled_Ind_CEB}
                    value={getValue("oxidantValue")}
                    id='oxidant'
                    name='oxidantValue'
                    onChange={handleInputChange}
                    isError={checkError("oxidantValue")}
                    inputText='mg/L'
                    placeholder='0.00'
                    defaultValue='2.00'
                    isFocused={isFocused === "oxidantValue"}
                    onBlur={e => handleBlur(e, ufTechStrings.oxidant)}
                    onFocus={() => handleFocus("oxidantValue")}
                  />
                  <InputRanges ranges={validations.oxidantValue} />
                </div>
              </div>
            </div>
            <div className='ceb-scaling-potential'>
              <CustomLabel label='CEB Scaling Potential' />
              <InputWithText
                type='number'
                disabled
                inputText='LSI'
                name='ceb_LSI'
                placeholder='0.00'
                onChange={handleInputChange}
                value={cebData.ceb_LSI}
                defaultValue='2.00'
                isFocused={isFocused === "ceb_LSI"}
                onBlur={e => handleBlur(e, "")}
                onFocus={() => handleFocus("ceb_LSI")}
              />
            </div>
          </CustomCard>
          <CustomCard
            className='disinfection-card'
            header={ufTechStrings.disinfectionCEB}
            tooltipLabel='Select oxidant chemical reagent and target doses or pH.'
          >
            <div className='oxidant'>
              <div className='label-switch'>
                <CustomHeading label={ufTechStrings.oxidant} />
                <ToggleSwitch
                  small
                  id='disOxidant'
                  name='disOxidantEnabled_Ind_CEB'
                  disabled={isChemicalDropdownDisabled("disOxidant") || !isIngeSelectedMemo}
                  checked={cebData.disOxidantEnabled_Ind_CEB}
                  onChange={handleEnableandDisable}
                />
              </div>
              <div className='input-select'>
                <div className='select'>
                  <CustomSelect
                    id='disOxidant'
                    disabled={!cebData.disOxidantEnabled_Ind_CEB}
                    value={cebData.disOxidantChemId}
                    name='disOxidantChemId'
                    onChange={handleSelect}
                  >
                    {!cebData.disOxidantEnabled_Ind_CEB && <option value={0}></option>}
                    {chemicalType?.disOxidant?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.displayName}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
                <div className='input'>
                  <InputWithText
                    id='disOxidant'
                    type='number'
                    disabled={!cebData.disOxidantEnabled_Ind_CEB}
                    inputText='mg/L'
                    name='disOxidantValue'
                    value={getValue("disOxidantValue")}
                    onChange={handleInputChange}
                    isFocused={isFocused === "disOxidantValue"}
                    onBlur={e => handleBlur(e, "")}
                    onFocus={() => handleFocus("disOxidantValue")}
                  />
                  <InputRanges ranges={validations.disOxidantValue} />
                </div>
              </div>
            </div>
          </CustomCard>
        </div>
        <div className='duration'>
          <CustomCard
            className='duration-card'
            header={ufTechStrings.duration}
            tooltipLabel='Provide duration of each step in the CEB protocol.'
          >
            <CustomRadioCheck
              className='same-as-backwash'
              disabled={false}
              id='sameAsBackwash_Ind'
              label='Same as Backwash'
              name='sameAsBackwash_Ind'
              checked={cebData.sameAsBackwash_Ind}
              onChange={handleEnableandDisable}
            />
            <div className='duration-input-wrapper-ceb'>
              <div className='big-text'>
                {/* CEB ceb_AirScour Bottom */}
                <CustomLabel label={ufTechStrings.airScour} />
                <InputWithText
                  type='number'
                  disabled={cebData.sameAsBackwash_Ind || DISABLED_INPUTS.includes(ufTechStrings.airScour)}
                  name='ceb_AirScour'
                  inputText='Sec'
                  placeholder='0.00'
                  value={isIngeSelectedMemo ? 0 : getValue("ceb_AirScour")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "ceb_AirScour"}
                  onBlur={e => handleBlur(e, "Air Scour")}
                  onFocus={() => handleFocus("ceb_AirScour")}
                  isError={checkError("ceb_AirScour")}
                />
                {!isIngeSelectedMemo && <InputRanges ranges={validations.ceb_AirScour} />}
              </div>
              <div className='big-text'>
                {/* CEB Drain */}
                <CustomLabel label={ufTechStrings.drain} />
                <InputWithText
                  type='number'
                  disabled={cebData.sameAsBackwash_Ind || DISABLED_INPUTS.includes(ufTechStrings.drain)}
                  name='drain'
                  inputText='Sec'
                  placeholder='0.00'
                  value={isIngeSelectedMemo ? 0 : getValue("drain")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "drain"}
                  onBlur={e => handleBlur(e, ufTechStrings.drain)}
                  onFocus={() => handleFocus("drain")}
                  isError={checkError("drain")}
                />
                {!isIngeSelectedMemo && <InputRanges ranges={validations.drain} />}
              </div>
              {/* Top Backwash */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.cebTopBackwash} />
                <InputWithText
                  type='number'
                  disabled={cebData.sameAsBackwash_Ind}
                  name={"backWash1_CEB"}
                  inputText='Sec'
                  value={getValue("backWash1_CEB")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "backWash1_CEB"}
                  onBlur={e => handleBlur(e, "Top Backwash")}
                  onFocus={() => handleFocus("backWash1_CEB")}
                  isError={checkError("backWash1_CEB")}
                />
                <InputRanges ranges={validations.backWash1_CEB} />
              </div>
              {/* Bottom Backwash */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.cebBottomBackwash} />
                <InputWithText
                  type='number'
                  disabled={cebData.sameAsBackwash_Ind || isUXASelected}
                  name={"backWash2_CEB"}
                  inputText='Sec'
                  value={isUXASelected ? 0 : getValue("backWash2_CEB")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "backWash2_CEB"}
                  onBlur={e => handleBlur(e, "Bottom Backwash")}
                  onFocus={() => handleFocus("backWash2_CEB")}
                  isError={checkError("backWash2_CEB")}
                />
                {!isUXASelected && <InputRanges ranges={validations.backWash2_CEB} />}
              </div>
              {/* Forward Flush */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.forwardFlush} />
                <InputWithText
                  type='number'
                  disabled={DISABLED_INPUTS.includes(ufTechStrings.forwardFlush) || cebData.sameAsBackwash_Ind}
                  name={"forwardFlush"}
                  inputText='Sec'
                  placeholder='0.00'
                  value={isIngeSelectedMemo ? 0 : getValue("forwardFlush")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "forwardFlush"}
                  onBlur={e => handleBlur(e, ufTechStrings.forwardFlush)}
                  onFocus={() => handleFocus("forwardFlush")}
                  isError={checkError("forwardFlush")}
                />
                {!isIngeSelectedMemo && <InputRanges ranges={validations.forwardFlush} />}
              </div>
              {/*  Chemical Soaking Duration */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.chemicalSoakingDuration} />
                <InputWithText
                  type='number'
                  disabled={false}
                  name={"chemicalSoakingDuration_CEB"}
                  inputText='min'
                  placeholder='0.00'
                  value={getValue("chemicalSoakingDuration_CEB")}
                  onChange={handleInputChange}
                  isFocused={isFocused === "chemicalSoakingDuration_CEB"}
                  onBlur={e => handleBlur(e, "Chemical Soaking Duration")}
                  onFocus={() => handleFocus("chemicalSoakingDuration_CEB")}
                  isError={checkError("chemicalSoakingDuration_CEB")}
                />
                <InputRanges ranges={validations.chemicalSoakingDuration_CEB} />
              </div>
              {/* BW Rinse Drain Bottom */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.bWRinseDrainBottom} />
                <InputWithText
                  type='number'
                  disabled={DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainBottom)}
                  name={"bwRinseDrainBottom"}
                  inputText='Sec'
                  placeholder=''
                  value={
                    DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainBottom) ? 0 : getValue("bwRinseDrainBottom")
                  }
                  onChange={handleInputChange}
                  onBlur={e => handleBlur(e, ufTechStrings.bWRinseDrainBottom)}
                  onFocus={() => handleFocus("bwRinseDrainBottom")}
                  isError={checkError("bwRinseDrainBottom")}
                />
                {!DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainBottom) && (
                  <InputRanges ranges={validations.bwRinseDrainBottom} />
                )}
              </div>
              {/* BW Rinse Drain Top */}
              <div className='big-text'>
                <CustomLabel label={ufTechStrings.bWRinseDrainTop} />
                <InputWithText
                  type='number'
                  disabled={DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainTop)}
                  name={"bwRinseDrainTop"}
                  inputText='Sec'
                  placeholder=''
                  value={DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainTop) ? 0 : getValue("bwRinseDrainTop")}
                  onChange={handleInputChange}
                  onBlur={e => handleBlur(e, ufTechStrings.bWRinseDrainTop)}
                  onFocus={() => handleFocus("bwRinseDrainTop")}
                  isError={checkError("bwRinseDrainTop")}
                />
                {!DISABLED_INPUTS.includes(ufTechStrings.bWRinseDrainTop) && (
                  <InputRanges ranges={validations.bwRinseDrainTop} />
                )}
              </div>
            </div>
          </CustomCard>
        </div>
        {inputError && <ProjectErrorPopup show close={closeErrorMessag} message={inputError} />}
      </CEBStyled>
    </>
  );
};

export default CEB;
